@import '../../constants/style';

.login-header {
  background-color: $color-white;
  display: block;
  padding: 23px 81px;
  position: fixed;
  top: 0;
  width: 100%;
}

.login-header-link {
  text-decoration: none;
}

.login-header__title {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;

  span {
    color: $color-orange;
  }
}

.login-main {
  align-items: center;
  background-color: $color-lightest-gray;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.login-box {
  background-color: $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 30px 30px 24px 30px;

  @media all and (min-width: 768px) {
    width: 526px;
  }
}

.login-box__header {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-top: 0;
  margin-bottom: 4px;
}

.login-box__paragraph {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 25px;
  margin-top: 0;
}

.login-box__input.MuiFormControl-root {
  display: flex;

  .MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.38);
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 22px;
  }

  .login-box-input-clear {
    cursor: pointer;
    height: 24px;
    position: relative;
    width: 24px;

    &:before {
      border-radius: 0.75px;
      background-color: #B2B2B2;
      content: '';
      height: 19px;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 1.5px;
    }

    &:after {
      border-radius: 0.75px;
      background-color: #B2B2B2;
      content: '';
      height: 19px;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 1.5px;
    }
  }

  .MuiInputBase-input {
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 1px;
  }
  .MuiInput-underline.Mui-error:after {
    border-bottom-color: $color-danger;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid $color-orange;
  }

  .MuiInput-underline:after {
    border-bottom-color: $color-orange;
  }

  .MuiInput-underline:before {
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }

  .MuiFormHelperText-root {
    color: rgba(0, 0, 0, 0.38);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 1px;

    &.Mui-error {
      color: $color-danger;
    }
  }
}

.login__button {
  background-color: $color-orange;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: $color-white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-top: 57px;
  outline: none;
  padding: 22px 0;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.15s ease;
  width: 100%;

  &:hover,
  &:focus {
    background-color: rgba(250, 100, 0, 0.8);
    outline: none;
  }
}

.login__link {
  background-color: $color-orange;
  border-radius: 8px;
  color: $color-white;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.15s ease-in-out;
  padding: 22px 0;

  &:hover,
  &:focus {
    background-color: rgba(250, 100, 0, 0.8);
  }
}

.login-input-container {
  position: relative;

  .char-remaining {
    right: 0;
  }
}

//p.login-board__participants-text {
//  margin-top: $large-margin;
//  margin-bottom: $default-padding;
//}
//
//.login-board__user-list {
//  margin-bottom: $large-margin;
//}
