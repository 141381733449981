@import "../../constants/style";

.account {
  background-color: $color-lightest-gray;
  min-height: 100vh;

  .container {
    max-width: 1107px;
  }
}

div#portal.account-portal {
  position: fixed;
}

div#portal.account-portal.account-portal--active {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 100;
}

.account-main {
  padding-top: 115px;
}

.account-container {
  display: flex;

  @media all and (max-width: 760px) {
    flex-direction: column;
  }
}

.account-tabs-container {
  width: 275px;

  @media all and (max-width: 760px) {
    width: 100%;
  }
}

.account-tabs {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  float: left;
  display: inline-block;
  width: 251px;
  padding: 24px 24px 0 24px;
  margin: 0 24px 0 0;

  @media all and (max-width: 760px) {
    width: 100%;
  }

  &.account-tabs--fixed {
    position: fixed;
    top: 106px;
  }
}

.account-tabs-options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.account-tabs-options__item {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 24px;
  position: relative;

  &.account-tabs-options__item--active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;

    &:before {
      background-color: $color-orange;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      content: "";
      left: -24px;
      top: -7px;
      display: inline-block;
      position: absolute;
      height: 32px;
      width: 2px;
    }
  }

  &.account-tabs-options__item--button {
    background-color: transparent;
    border: 0;
    display: list-item;
    font-family: "Inter", sans-serif;
    padding: 0;

    &:hover,
    &:focus {
      outline: 0;
    }
  }
}

.account-content__title {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  top: -50px;
  margin: 0;
  position: absolute;
  white-space: nowrap;

  @media all and (max-width: 760px) {
    white-space: normal;
  }
}

.account-tabs-content-container {
  margin-right: 24px;

  @media all and (min-width: 768px) {
    width: 434px;
  }

  @media all and (max-width: 760px) {
    width: 100%;
    margin-top: 80px;
  }
}

.account-tabs-content {
  display: block;
  position: relative;
}

.account-content {
  background-color: $color-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 24px;
}

.account__provider {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  margin-bottom: 24px;
}

.account__button {
  background-color: $color-orange;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 21px;
  padding-top: 21px;
  width: 100%;
  margin-bottom: 14px;

  &:disabled {
    cursor: auto;
    opacity: 0.8;
  }
}

.account-update-message {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.3s linear;

  &.account-update-message--active {
    opacity: 1;
  }
}

.account-anonymous {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: 13px 16px 16px 16px;
  max-width: 251px;

  @media all and (max-width: 760px) {
    width: 100%;
    max-width: none;
  }
}

.account-anonymous__text {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
}

.account-anonymous__link {
  background: $color-lightest-gray;
  border: 2px solid $color-orange;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.15s ease;
  opacity: 1;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
}

.account-modal {
  @media all and (min-width: 992px) {
    &.modal__content-wrapper {
      width: 442px;
    }
  }

  .modal__content.info-modal {
    margin: 64px 0;
  }

  .info-modal__description {
    max-width: 322px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
