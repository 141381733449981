@import '../../../../constants/style';

.side-modal__box.team-create-box {
  padding: 11px 24px 16px 24px;

  .side-modal-input-container {
    margin-bottom: 1px;
  }

  .side-modal__input input {
    padding-top: 6px;
  }
}

.team-create-submit {
  bottom: 14px;
  position: absolute;
  width: 100%;
}

.team-create-submit__button {
  border: 0;
  background: $color-orange;
  border-radius: 8px;
  color: $color-white;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding: 21px 0;
  margin: 0 12px;
  text-align: center;
  transition: opacity .15s;
  width: calc(100% - 24px);

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.9;
  }
}
