@import "../../constants/style";

@media print {
  .buttonbar {
    display: none;
  }
}

html, body {
  overflow: initial !important;
  background: $color-white !important;
}

.side-modal__box.export-first-box {
  border-bottom: 1px solid #EFEFEF;
  margin-top: 0;
  padding: 24px 24px 23px 24px;
  position: relative;
}

.export-box {
  background-color: $color-white;
  padding: 31px 24px 36px 24px;
  border-bottom: 8px solid $color-lightest-gray;

  .export__title {
    margin-bottom: 10px;
  }

  .export__info {
    margin-bottom: 0;
  }
}

.export__logo {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  margin: 0;

  span {
    color: $color-orange;
  }
}

.export-column__main {
  border-bottom: 8px solid #F3F3F3;
}

.export-column__header {
  align-items: center;
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  padding: 26px 24px 25px 24px;
}

.card-list {
  list-style: none;
  margin: 0;
  padding: 0 24px 0 24px;

  .card-list__item:last-child {
    border-bottom: none;
  }
}

.card-list__item {
  border-bottom: 1px solid #EFEFEF;
  margin-top: 17px;
}

.print-view-board__card-blockquote {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 16px 0;
}

.print-view-board__cite {
  color: rgba(0, 0, 0, 0.9);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 1px;
}

.export-footer {
  padding: 40px 0;
  text-align: center;
}

.export-footer__logo {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 6px;

  span {
    color: $color-orange;
  }
}

.export-footer__text {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.export-column__main .export-group:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.export-group {
  background-color: rgb(243, 246, 248);
  border-radius: 8px;
  margin-top: 24px;
}

.export-group__name {
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  font-size: 20px;
  margin: 0;
  padding: 16px 0 16px 8px;

  .export-column-header__count {
    margin-left: 7px;
  }
}
