@import '../../../../constants/style';

.add-dot {
  margin: 0 0 0 .25em;
  padding: 0;
  cursor: pointer;

  background: none;
  border: none;
  outline: none;

  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 16px;

  @include animate(box-shadow);
}

.add-dot.add-dot--disabled {
  cursor: auto;
}

.add-dot.add-dot--clickable:focus,
.add-dot.add-dot--clickable:hover {
  box-shadow: 0 0 0 4px $color-gray;
}

.add-dot__plus-circle .add-dot__plus-circle-icon-circle {
  @include animate(fill);

  fill: $color-gray;
  stroke: $color-gray;
}

.add-do.add-dot--clickablet:focus .add-dot__plus-circle-icon-circle,
.add-dot.add-dot--clickable:hover .add-dot__plus-circle-icon-circle {
  fill: $color-orange;
}

.add-dot__plus-circle .add-dot__plus-circle-icon-plus-line {
  @include animate(stroke-width);
}

.add-dot.add-dot--clickable:focus .add-dot__plus-circle-icon-plus-line,
.add-dot.add-dot--clickable:hover .add-dot__plus-circle-icon-plus-line {
  stroke-width: 2.5;
}
