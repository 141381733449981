@import '../../../../constants/style';

.footer {
  color: $color-dark;
  padding-top: 85px;
  padding-bottom: 48px;
  text-align: center;
}

.social-links {
  margin-bottom: 48px;
}

.social-links__link {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}