@import '../../constants/style';

.stack {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin old-browser() {
  .stack {
    display: flex;
    flex-flow: row wrap;
  }

  .stack > * {
    margin: $default-margin / 2;
    min-width: 170px;
    flex: 1 1 360px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  @include old-browser();
}

@supports not (display: grid) {
  @include old-browser();
}

@supports (display: grid) {
  .stack {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;

    grid-row-gap: $default-margin;
    grid-column-gap: $default-margin;
  }
}

