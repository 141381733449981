@import '../../constants/style';

.start-button {
  display: flex;
  min-height: 50px;
  margin: 0 12px 12px 12px;
  position: absolute;
  width: calc(100% - 24px);
  bottom: 0;
}

.start-button__start {
  align-self: stretch;
  color: $color-white;
  box-sizing: border-box;
  border: none;
  flex: 1 0 auto;
  outline: none;
  padding-bottom: 22px;
  padding-top: 22px;

  background-color: $color-orange;
  border-radius: 8px;
  transition: background 0.15s ease;
  cursor: pointer;
  text-transform: uppercase;
}

.start-button__start:focus,
.start-button__start:hover {
  background: $color-orange;
}

.start-button__start-label {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

//.start-button__start-mode {
//  font-size: .75em;
//}

//.start-button__dropdown-toggle {
//  box-sizing: border-box;
//  border: 2px solid $color-orange;
//  outline: none;
//  font-size: 16px;
//  line-height: 1;
//  font-weight: 700;
//  color: $color-orange;
//  padding: 9px;
//
//  background-color: $color-white;
//  border-left: none;
//  border-top-right-radius: 3px;
//  border-bottom-right-radius: 3px;
//  transition: background 0.15s ease;
//  cursor: pointer;
//  text-transform: uppercase;
//}

//.start-button__dropdown-toggle-button {
//  position: absolute;
//  top: -10px;
//  left: -16px;
//}
//
//.start-button__dropdown-toggle svg {
//  width: 32px;
//  height: 32px;
//}
//
//.start-button__dropdown-toggle svg * {
//  fill: currentColor;
//  transition: fill 0.15s ease;
//}
//
//.start-button__dropdown-toggle:focus,
//.start-button__dropdown-toggle:hover {
//  background: $color-orange;
//  color: $color-white;
//}
//
//.start-button__dropdown-wrapper {
//  box-sizing: border-box;
//  margin-left: 0 !important;
//  flex: 0 0 auto;
//  align-self: stretch !important;
//}
