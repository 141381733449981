@import '../../../constants/style';

.column-name-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  height: 29px;
  justify-content: space-between;
}

.column-name {
  margin: 0;

  color: #000000;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.column-name--input {
  border: 1px solid transparent;
  border-radius: 8px;
  caret-color: $color-orange;
  transition: border-color .3s ease;
  padding: 5px;
  width: 100%;

  &:focus {
    border-color: $color-orange;
    outline: 0;
  }
}

.column-name__count {
  background-color: $color-orange;
  border-radius: 16px;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  flex: none;
  display: inline-block;
  line-height: 24px;
  margin-left: 6px;
  margin-right: 6px;
  width: 24px;
  height: 24px;
  text-align: center;
}
