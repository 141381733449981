@import "../../../constants/style";

.modal__content.delete-modal {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  max-width: 442px;
  text-align: center;
  position: relative;
  padding: 12px;
}

.delete-modal__title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 42px;
  margin-bottom: 12px;
}

.delete-modal__message {
  margin-bottom: 32px;
  padding: 0 48px;
}

.delete-modal__warning {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.delete-modal-button__buttons {
  display: flex;
  justify-content: space-around;
}

.delete-modal-button {
  align-self: flex-end;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding: 21px;
  transition: opacity .3s;
  width: 100%;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.delete-modal-button__cancel {
  background-color: #EFEFEF;
  color: #000000;
  margin-left: 6px;

}

.delete-modal-button__danger {
  background-color: $color-danger;
  color: $color-white;
  margin-right: 6px;
}
