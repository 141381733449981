@import '../../constants/style';

.add-card {
    flex: 1;
    display: flex;
}

.add-card__button {
    cursor: pointer;
    box-sizing: border-box;
    align-self: center;

    width: 32px;
    height: 32px;
    line-height: 28px;

    font-weight: bold;

    border: none;
    color: currentColor;
    border-radius: 50%;
    outline: none;
    background: none;

    margin: 0;
    padding: 0;
    position: relative;

    &:before {
      background-color: #B2B2B2;
      border-radius: 2px;
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: background-color .3s;
      height: 2px;
      width: 14px;
    }

    &:after {
      background-color: #B2B2B2;
      border-radius: 2px;
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: background-color .3s;
      height: 14px;
      width: 2px;
    }

    &.add-card__button--focused {
      &:before, &:after {
        background-color: $color-orange;
      }
    }

    &:hover {
      &:before, &:after {
        background-color: $color-orange;
      }
    }
}

.add-card__button > span {
    display: block;
    width: auto;
    height: 24px;
    line-height: 32px;
}

.add-card__hint {
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    line-height: 1;
    position: absolute;
    bottom: 15px;
    opacity: 0;
    transition: opacity .3s;

    strong {
      color: #000000;
      font-weight: 700;
    }
}

.add-card__hint--show {
    opacity: 1;
}

.add-card__plus-circle .add-card__plus-circle-icon-circle,
.add-card__plus-circle .add-card__plus-circle-icon-plus-line {
    stroke: currentColor;
    @include animate(stroke);
    @include animate(fill);
}


.add-card.add-card--theme-light .add-card__button:hover .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-light .add-card__button:focus .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-light .add-card__button:active .add-card__plus-circle-icon-circle {
    fill: $color-dark-gray;
    stroke: $color-dark-gray;
}

.add-card.add-card--theme-light .add-card__button:hover .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-light .add-card__button:focus .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-light .add-card__button:active .add-card__plus-circle-icon-plus-line {
    stroke: $color-white;
}

.add-card.add-card--theme-dark .add-card__button:hover .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-dark .add-card__button:focus .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-dark .add-card__button:active .add-card__plus-circle-icon-circle {
    fill: $color-white;
    stroke: $color-white;
}

.add-card.add-card--theme-dark .add-card__button:hover .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-dark .add-card__button:focus .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-dark .add-card__button:active .add-card__plus-circle-icon-plus-line {
    stroke: $color-dark-gray;
}

.add-card.add-card--theme-mint .add-card__button:hover .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-mint .add-card__button:focus .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-mint .add-card__button:active .add-card__plus-circle-icon-circle {
    fill: $color-dark-gray;
    stroke: $color-white;
}

.add-card__icon_enter {
    height: 16px !important;
    width: 16px !important;
    margin-left: 5px;
    margin-right: 3px;
}

.action-wrapper--theme-light .add-card__hint {
    color: $color-dark-gray;
}

.action-wrapper--theme-light svg {
    fill: $color-dark-gray;
}

.action-wrapper--theme-dark .add-card__hint {
    color: $color-light-gray;
}

.action-wrapper--theme-dark svg {
    fill: $color-light-gray;
}

.action-wrapper--theme-mint .add-card__hint {
    color: $color-light-gray;
}

.action-wrapper--theme-mint svg {
    fill: $color-light-gray;
}
