.dashboard-action-items {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 24px;
  padding-bottom: 32px;

  @media only screen and (max-width: 576px) {
    grid-template-columns: auto;
  }
}

.action-items-checking {
  text-align: center;
}

.action-items-unauthorized {
  max-width: 1110px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 700;
  text-align: center;

  span {
    text-decoration: none;
    color: #fa6400;
    cursor: pointer;
  }
}

.action-items-sidebar {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 250px;
  align-self: flex-start;
  width: 150px;

  @media only screen and (max-width: 576px) {
    width: 100%;
    padding: 12px 24px;
  }
}

.action-items-sidebar-options {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media only screen and (max-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.action-items-sidebar-options__item {
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;

  &--active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
    position: relative;

    &:before {
      background-color: #fa6400;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      content: "";
      left: -24px;
      top: -7px;
      display: inline-block;
      position: absolute;
      height: 32px;
      width: 2px;

      @media only screen and (max-width: 576px) {
        top: 100%;
        left: 0;
        height: 2px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.action-items__button {
  align-items: center;
  background-color: #fa6400;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 14px 0;
  width: 100%;
  transition: opacity 0.15s;
  margin-bottom: 24px;
}

.action-items-create-button-container {
  padding: 0 24px;
}

.action-items-create-button {
  margin-top: 24px;
}

.action-items-error {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.action-items-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
}

.action-item-single-card {
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  overflow: hidden;
  padding: 18px 24px 22px 24px;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  h3 {
    font-size: 16px;
    margin: 0;
  }

  &__text {
    cursor: pointer;
    max-height: 120px;
    text-overflow: ellipsis;
  }

  &__footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__done {
    font-size: 12px;
    text-transform: uppercase;
    color: green;
  }

  &__empty {
    opacity: 0;
  }

  &:hover {
    transform: scale(1.02);
  }
}

.action-item-single-card__settings {
  position: absolute;
  right: 12px;
  top: 22px;

  .dd-menu-items {
    width: 98px;

    ul {
      background: #ffffff;
      border: 1px solid rgba(44, 44, 49, 0.06);
      border-radius: 8px;
      box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
    }
  }
}

.action-item-single-card__settings-icon {
  background-color: white;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 24px;
  width: 24px;
  z-index: 1;

  span {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
    height: 4px;
    width: 4px;
  }
}

.action-item-single-card__settings-item {
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;

  &.action-item-single-card__settings-item--first {
    padding-top: 15px;
    padding-bottom: 5px;
  }

  &.action-item-single-card__settings-item--last {
    padding-bottom: 15px;
    padding-top: 5px;
  }

  &.action-item-single-card__settings-item--only {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.side-modal__assignUserContainer {
  position: relative;

  &--first {
    z-index: 2;
  }
}

.side-modal__userList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 12px 0;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.side-modal__singleUserInLinst {
  padding: 12px 24px;

  &:hover {
    background-color: #dee3e7;
    cursor: pointer;
  }
}

.side-modal__singleUserInLinst--notAssigned {
  padding: 12px 24px;
}

.side-modal__assignedUser {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;

  user-select: none;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #dee3e7;
  font-size: 15px;
  padding: 12px;
}

.action-side-modal__boardLabel {
  color: rgba(0, 0, 0, 0.38);
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 12px;
  display: block;
}

.action-side-modal__boardLink {
  text-decoration: none;
  color: black;
  transition: all 0.3s;

  &:hover {
    color: #fa6400;
  }
}

.action-card__content {
  box-sizing: content-box;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  height: 100px;
  margin: 0;
  max-width: 95%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  text-overflow: ellipsis;
  padding: 0;
  white-space: pre-wrap;

  div {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical !important;
    display: -webkit-box;
  }
}

.action-card__more-content-indicator {
  background-color: #ffffff;
  color: #fa6400;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 0;
  bottom: 0;

  height: 24px;
  width: 100%;

  text-align: left;
}

.action-items-no-items-link {
  color: #fa6400;
  text-decoration: none;
}
