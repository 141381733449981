@import '../../constants/style';

.group-card {
  background: $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  position: relative;
  height: 160px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  z-index: 2;
}

.group-card-wrapper {
  background: $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 18px 24px 22px 24px;
  position: relative;
  z-index: 2;
}

.group-card__shadow {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  height: 100%;
  position: absolute;
  top: 6px;
  left: 4px;
  width: calc(100% - 8px);
  z-index: -1;
}

.group-card:hover {
  transform: scale(1.02);
}

//.group-card__shadow {
//  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
//  border-radius: 8px;
//  background: $color-white;
//  content: '';
//  display: block;
//  position: absolute;
//  height: 160px;
//  width: calc(100% - 8px);
//  left: 0;
//  right: 0;
//  margin-left: auto;
//  margin-right: auto;
//  top: 5px;
//  z-index: 1;
//}

.group-card__menu {
  position: absolute;
}

.group-card__text {
  align-items: center;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  justify-content: center;
  padding: 24px;
  word-break: break-word;

  span {
    max-height: 44px;
    word-break: break-all;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.group-card__footer {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 9;

  &.group-card__foter--multiuser {
    .group-card__author-name {
      display: none;
    }

    .group-card__avatar {
      margin-right: 0;
    }

    .group-card__avatar--seconds {
      border: 2px solid $color-white;
      margin-left: -10px;
    }
  }
}

.group-card-avatars {
  display: flex;
}

.group-card-avatar-container {
  align-items: center;
  display: flex;
}

.group-card__avatar {
  margin-right: 10px;
}

.group-card__author-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
}

.group-card-group {
  background-color: $color-white;
  border: 3px dashed $color-orange;
  box-sizing: border-box;
  color: $color-orange;
  display: none;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 1;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.group-card-group__text {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
}

.card-stack__count {
  background-color: $color-orange;
  border-radius: 50%;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  left: 18px;
  position: absolute;
  text-align: center;
  top: 18px;
  width: 24px;
  z-index: 999;
}
