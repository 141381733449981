@import '../../constants/style';

.action-wrapper {
  background: #ffffff;
  border-right: 1px solid #EFEFEF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  z-index: 999;
}

.action {
  box-sizing: border-box;
  padding: 24px 11px 24px 20px;
  position: relative;
  width: 100%;
}

//@media #{$desktop} {
//  .action {
//    padding: $default-padding $large-padding;
//  }
//}

//.action-wrapper--theme-light {
//  color: $color-dark-gray;
//  background: $color-white;
//}
//
//.action-wrapper--theme-dark {
//  color: $color-white;
//  background: $color-dark-gray;
//}
//
//.action-wrapper--theme-mint {
//  color: $color-white;
//  background: $color-orange;
//}
