@import "../../../constants/style";

.membership-request-container {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  box-sizing: border-box;
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 24px;
  max-width: 320px;
  z-index: 500;
}

.membership-request {
  position: relative;
}

.membership-request__title {
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 14px;
}

.membership-request__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 3px;
  height: 24px;
  width: 24px;

  &:before {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 0.75px;
    content: '';
    height: 19px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(-45deg);
    width: 1.5px;
  }

  &:after {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 0.75px;
    content: '';
    height: 19px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);
    width: 1.5px;
  }
}

.membership-request__request-message {
  margin-bottom: 18px;
}

.membership-request__prompt {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  .membership-request__member-name {
    color: #000000;
  }
}

.membership-request__member-photo {
  border-radius: 50%;
  display: inline-block;
  float: left;
  max-width: 32px;
  margin-right: 15px;
  height: auto;
}
