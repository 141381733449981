@import '../../../constants/style';

.plans {
  background-color: #F3F3F3;
  text-align: center;
}

.payment-plans {
  width: 100%;
}

.payment-plans-text {
  display: flex;
  justify-content: center;
  line-height: 1.7;
  margin-bottom: 60px;
}

.payments-plans-container {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  text-align: center;
  margin: 40px 0 40px 0;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
    gap: 30px;
    margin: 40px 0;
  }
}

.single-plan {
  background-color: #E0EFFC;
  position: relative;
  border-radius: 3px;
  
  &__content {
    padding: 16px 16px 0 16px;
    width: 180px;
    height: 200px;
    border: 3px solid transparent;

    @media only screen and (max-width: 780px) {
      width: auto;
    }
    
    &--selected {
      border: 3px solid #FA6400;
    }
  }

  &--recomend {
    @media only screen and (max-width: 780px) {
      margin-top: 40px;
    }
  }

  &__title {
    color: #969393;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__price {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 32px;
  }

  &__singleLimit {
    color: #989ba0;
    margin-bottom: 16px;
  }

  &__button {
    outline: none;
    width: 100%;
    padding: 12px;
    background-color: #69ACF8;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: 3px solid #69ACF8;
    border-top: none;
    border-radius: 0 0 3px 3px;

    &:hover {
      cursor: pointer;
    }

    &--selected {
      border: 3px solid #FA6400;
      border-top: none;
    }
  }

  &__recomend {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    background-color: #F96868;
    color: white;
    border-radius: 3px 3px 0 0;
    border-bottom: 3px solid #F96868;

    &--selected {
      border-bottom: 3px solid #FA6400;
    }
  }
}

.plans-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  min-height: 100vh;
  padding-top: 55px;
  overflow-y: hidden;
}

@media only screen and (min-width: 1200px) {
  .plans-container {
    width: 1077px;
  }
}

.register-plans {
  margin: 0 auto 40px auto;
  border: 2px solid black;
  width: 740px;

  @media only screen and (max-width: 780px) {
    width: auto;
  }

  &__header {
    background-color: #FA6400;
    font-size: 30px;
    font-weight: 600;
    padding: 16px 0;
    color: white;
    text-align: center;
    border-bottom: 2px solid black;
    margin-bottom: 80px;

    @media only screen and (max-width: 780px) {
      margin-bottom: 20px;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid black;
    padding: 30px 60px;
    background-color: white;
    color: #B2B2B2;
    font-weight: 800;

    @media only screen and (max-width: 780px) {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
    
    & > * {
      cursor: pointer;
    }
  }
}

.plan {
  cursor: pointer;
  min-width: 270px;

  label {
    cursor: pointer;
  }
}

.plan__header {
  background-color: $color-orange;
  border-radius: 2px;
  color: $color-white;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  z-index: 9;
}

.plan__info {
  display: flex;
  align-items: center;
  line-height: 32px;
}

.plan__check {
  cursor: pointer;
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
}

.plan__name {
  display: inline-block;
  font-size: 16px;
}

.plan-pricing {
  display: flex;
  line-height: 32px;
}

.plan__price {
  display: inline-block;
  font-size: 24px;
}

.plan__duration {
  display: inline-block;
  font-size: 16px;
}

.plan-description {
  border-bottom: 1px solid $color-dark;
  border-left: 1px solid $color-dark;
  border-right: 1px solid $color-dark;
  margin-top: -1px;
}

.plan-features {
  list-style: none;
  margin: 0;
  padding-bottom: 15px;
  padding-left: 0;
  padding-top: 27px;
}

.plan__feature {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;
  padding-left: 35px;
  position: relative;

  &:before {
    background-color: $color-dark;
    content: '';
    display: inline-block;
    height: 1px;
    position: absolute;
    bottom: 9px;
    left: 8px;
    transform: rotate(45deg);
    width: 8px;
  }

  &:after {
    background-color: $color-dark;
    content: '';
    display: inline-block;
    height: 1px;
    position: absolute;
    bottom: 12px;
    left: 12px;
    transform: rotate(-45deg);
    width: 16px;
  }
}

.plan__submit {
  background-color: $color-orange;
  border: 0;
  border-radius: 2px;
  color: $color-white;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  padding: 9px 15px;
  width: 300px;
  margin-bottom: 40px;

  @media only screen and (max-width: 780px) {
    margin: 0 20px 40px 20px;
  }
}

.right-arrow {
  background-color: $color-white;
  display: inline-block;
  height: 2px;
  width: 12px;
  margin-bottom: 4px;
  margin-left: 10px;
  position: relative;

  &:before {
    background-color: $color-white;
    content: '';
    display: inline-block;
    height: 2px;
    width: 8px;
    transform: rotate(-45deg);
    position: absolute;
    right: -2px;
    bottom: -2px;
  }

  &:after {
    background-color: $color-white;
    content: '';
    display: inline-block;
    height: 2px;
    width: 8px;
    transform: rotate(45deg);
    position: absolute;
    right: -2px;
    top: -2px;
  }
}
