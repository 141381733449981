@import '../../../../constants/style';

.export__title {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 12px;
  margin-top: 0;
}

.export__info {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 28px;
}

.export-button {
  align-items: center;
  background-color: $color-orange;
  border-radius: 8px;
  color: $color-white;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  padding: 12px 18px 12px 12px;
}

.export-button__icon {
  display: inline-block;
  margin-right: 7px;
  height: 20px;
  width: 20px;

  svg {
    height: 20px;
    width: 20px;

    * {
      fill: $color-white;
    }
  }
}

.export-column-header {
  align-items: center;
  display: flex;
  padding: 2px 0 26px 0;
  position: relative;

  &:after {
    background-color: #EFEFEF;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    left: -24px;
    bottom: 0;
    width: calc(100% + 48px);
  }
}

.export-column-header__title {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin: 0 7px 0 0;
}

.export-column-header__count {
  background-color: $color-orange;
  border-radius: 50%;
  color: $color-white;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  height: 24px;
  width: 24px;
}

.export-column-card {
  margin-top: 17px;
  position: relative;

  &:after {
    background-color: #EFEFEF;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
  }

  &:last-child {
    &:after {
      display: none;
    }

    .export-column-card__votes {
      margin-bottom: 0;
    }
  }
}

.export-column-card__title {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 1px;
}

.export-column-card__description {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  word-break: break-all;
}

.export-column-card__votes {
  border: 1px solid $color-orange;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.9);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  padding: 2px 17px;
}

.export-column-group {
  background-color: rgb(243, 246, 248);
  border-radius: 8px;
  margin-top: 24px;
  padding-bottom: 17px;
}

.export-column-group__title {
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  font-size: 20px;
  margin: 0;
  padding: 16px 0 16px 8px;

  .export-column-header__count {
    margin-left: 7px;
  }
}

.export-column-group-cards {
  padding-left: 24px;
  padding-right: 24px;
}
