@import '../../constants/style';

.column-overview.portal__content {
  background-color: $color-lightest-gray;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  border-radius: 8px;
  justify-content: flex-start;
  margin: 30px;
  min-height: auto;
  padding: 0;

  .portal__close-button {
    height: 28px;
    position: absolute;
    top: 27px;
    right: 20px;
    width: 28px;
  }

  .portal__stop-propagation {
    height: 100%;
  }

  .portal__close-button-text {
    &:before {
      background-color: rgba(0, 0, 0, 0.9);
      top: -1px;
    }

    &:after {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
}

.column-overview__header {
  background-color: $color-white;
  padding: 26px 48px 26px 24px;

  .column-name-wrapper {
    height: 30px;
  }
}

.column-overview__header .column-name {
  font-family: 'Inter', sans-serif;
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.column-overview__stack {
  padding: 16px;
}
