@import "../../constants/style";

.header-user {
  background-color: #ffffff;
  padding-top: 25px;
  padding-bottom: 25px;

  &--silver {
    background-color: #f3f3f3;
  }

  .header__half-container {
    width: 50%;
  }
}

.header__logo-link {
  text-decoration: none;
}

.header__text-logo {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
}

.header__text-logo--orange {
  color: $color-orange;
}

.header__user-icon {
  text-align: right;
  position: relative;

  .avatar {
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }
}

.header__notificationNumber {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 8px;
  font-size: 12px;
  line-height: 1;

  user-select: none;
  cursor: pointer;
  background: red;
  color: #fff;
  text-align: center;
}

.header__toggle-btn.dd-menu {
  display: inline-block;

  .dd-menu-items {
    top: 16px;
    right: 1px;
    margin-top: 0;
  }

  ul.dd-items-right {
    background: #ffffff;
    border: 1px solid rgba(44, 44, 49, 0.06);
    box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding-top: 13px;
    padding-bottom: 3px;
  }

  .header__menu-item {
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    min-width: 96px;
    text-align: center;

    &--switchAcc {
      min-width: 135px;
    }

    .header__menu-link {
      color: rgba(0, 0, 0, 0.9);
      display: inline-block;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      padding: 0;

      &:focus {
        background-color: $color-white;
      }
    }
  }
}

.header__menu-email {
  text-align: right;
  transform: translateX(20%);
  margin-top: 8px;

  @media only screen and (max-width: 780px) {
    display: none;
  }
}
