@import '../../constants/style';

.team-names {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-top: 19px;
}

.team-names-selected {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
  margin-right: 24px;
  margin-left: 24px;

  &.team-names-selected--open {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
}

.team-names__team {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.team-name__arrow {
  display: flex;
  height: 12px;
  transform: rotate(90deg);
  transition: transform .3s;
  width: 12px;

  &.team-name__arrow--up {
    transform: rotate(-90deg);
  }

  svg {
    height: 12px;
    width: 12px;
  }
}

.team-name-list {
  list-style: none;
  margin: 0;
  height: 0;
  transition: height .3s;
  padding: 0;

  &.team-name-list--opened {
    height: 100%;
    padding: 8px 0;

    li {
      display: block;
    }
  }

  li {
    display: none;
  }
}

.team-name-list__item {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  transition: background-color .3s;
  padding: 13px 24px;

  &:hover,
  &:focus {
    background-color: #F6F6F6;
  }
}
