@import '../../../constants/style';

.expanded {
  max-width: 343px;

  @media all and (min-width: 992px) {
    min-width: 343px;
  }
}

.expanded-header {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;

  .char-remaining {
    right: 40px;
    top: 8px;
  }
}

.expanded__title {
  background-color: $color-white;
  box-sizing: border-box;
  width: 100%;

  .MuiInputBase-input {
    border: none;
    caret-color: $color-orange;
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 0;
    text-align: center;
  }

  &.Mui-error {
    border-bottom: 1px solid $color-danger;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.expanded-cards {
  padding: 0;
  margin: 16px 0 0 0;

  .card__root {
    margin-bottom: 16px;
    transform: translate(0, 0);

    .card--disabled {
      border-radius: 8px;
      border: 0;
    }
  }
}
