@import '../../constants/style';

.loading-screen {
  align-items: center;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.loading-screen-logo {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 31px;

  .loading-screen-logo__team {
    color: $color-orange;
  }
}

.loading-screen__progress-bar {
  background-color: #EFEFEF;
  border-radius: 6px;
  height: 6px;
  margin-bottom: 30px;
  width: 330px;

  @media all and (max-width: 499px) {
    width: 70%;
  }
}

.loading-screen__current-progress {
  animation-name: progress;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-play-state: running;
  animation-iteration-count: infinite;
  background-color: $color-orange;
  border-radius: 6px;
  height: 6px;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.loading-screen__message {
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 16px 0 15px 0;
  height: 22px;
  width: 100%;
  text-align: center;
}

.loading-screen__status {
  display: block;
  font-family: $theme-font-family;
  font-size: $theme-font-size;
  color: $color-dark;
  margin-top: $default-margin;
  text-align: center;
}
