@import '../../../../constants/style';

.description {
  background-color: $color-dirty-gray;
  color: $color-dark;
  font-family: $theme-font-family;
  padding-top: 60px;
}

.description__title {
  font-size: 32px;
  line-height: 40px;
  margin-top: 15px;
  margin-bottom: 50px;
}

.description__text {
  font-size: 16px;
  line-height: 25px;
  margin-top: 0;
}

.description-images {
  display: flex;
}

.description__larger-img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.description__smaller-img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
}