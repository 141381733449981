.notification-content {
  padding: 24px;
}

.notification-label {
  color: #fa6400;
  font-weight: 600;
  margin-bottom: 20px;
}

.single-notification {
  border-radius: 3px;
  padding: 12px;
  padding-bottom: 24px;
  margin-bottom: 12px;
  background-color: #fff;
  cursor: pointer;
}

.single-notification-link {
  text-decoration: none;
  color: black;
}

.single-notification-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.single-notification-title {
  font-size: 20px;
  font-weight: 600;
}

.single-notification-time {
  font-size: 12px;
  color: #b2b2b2;
}

.notification-group-title {
  color: #9f9f9f;
  margin-bottom: 14px;
}

.notification-settings-container {
  position: relative;

  .dd-menu {
    position: absolute;
  }

  .dd-menu .dd-menu-items ul {
    background: #ffffff;
    border: 1px solid rgba(44, 44, 49, 0.06);
    border-radius: 8px;
    box-shadow: 0 24px 32px 0 rgba(39, 50, 47, 0.15);
    box-sizing: border-box;
    padding: 10px 15px;
    margin-right: 10px;
  }
}

.notification-settings {
  position: absolute;
  top: 0;
  right: -10px;
  cursor: pointer;
}

.notification-settings-dropdown__icon {
  color: #b2b2b2;
  cursor: pointer;
  font-size: 23px;
  transform: rotate(90deg);
}
