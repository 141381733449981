@import "../../constants/style";

$button__default-color: #ffffff;

.button {
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: block;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  transition: opacity 0.15s ease-in;
  outline: none;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 100%;

  &:hover,
  &:focus {
    opacity: .8;
  }
}

.button--primary {
  color: $color-white;
  background: $color-orange;
}

.button--secondary {
  $color: $color-orange;

  color: $color;
  background: $button__default-color;
}
