@import '../../constants/style';

.input__wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 5px;
}

.input__label {
  text-transform: uppercase;
  font-size: $theme-font-size;
  font-weight: 500;
  margin-bottom: $base-unit * 2;
}

.input__description {
  margin-top: $base-unit * 1.5;
  font-size: $theme-font-size - $base-unit / 2;
}

.input__description--error {
  color: red;
}

.input {
  background: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  caret-color: $color-orange;
  color: #000000;
  flex: 1;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 24px;
  outline: none;
  padding: 0;
}

.input--underlined > .input {
  border-bottom: 1px solid $color-middle-gray;
}

.input--underlined.input--error > .input {
  border-bottom-color: red;
}

.input--underlined > .input:focus {
  border-bottom: none;
}

.input--underlined > .input__underline {
  margin-top: -1px;
  margin-bottom: 0;
}

.input--underlined > .input:focus + .input__underline {
  margin-top: -1px;
  margin-bottom: 0;
}

.input--error > .input__underline {
  background-color: red !important;
}

//.input__underline {
//  align-self: center;
//
//  background-color: currentColor;
//  height: 1px;
//  transition: width 0.25s linear;
//  width: 0;
//
//  margin-top: 1px;
//}

.input__focus-theme-mint > .input:hover::placeholder,
.input__focus-theme-mint > .input:focus::placeholder {
  color: $color-orange;
}

//.input__focus-theme-mint > .input__underline {
//  background-color: $color-orange;
//}
//
//.input:not([disabled]):focus + .input__underline,
//.input:not([disabled]):hover + .input__underline {
//  width: 100%;
//}
//
//.input:not([disabled]):focus + .input__underline {
//  margin-top: 0;
//  height: 2px;
//}

.input::placeholder {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.38);
  letter-spacing: 0;

  transition: color .25s linear;
}

.input--inverted-placeholder::placeholder {
  //font-weight: 400;
}
