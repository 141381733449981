@import '../../../../constants/style';

.landing-intro {
  border-bottom: 1px solid $color-dark;
  color: $color-dark;
  font-family: $theme-font-family;
  text-align: center;
  padding-top: 150px;
}

.landing-intro__title {
  font-size: 44px;
  line-height: 56px;
  letter-spacing: 2px;
  margin-top: 0;
  margin-bottom: 28px;
}

.landing-intro__text {
  margin-bottom: 46px;
  font-size: 18px;
}

.landing-intro__button {
  border: 0;
  border-radius: 4px;
  background-color: $color-orange;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin-bottom: 60px;
  padding: 16px 40px;
  text-decoration: none;
  transition: transform .3s;

  &:hover {
    transform: scale(1.04);
  }
}

.landing-intro__snapshot {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: block;
  max-width: 100%;
}
