@import '../../../../constants/style';

.features {
  box-sizing: border-box;
  color: $color-dark;
  font-family: $theme-font-family;
  padding-top: 88px;
  padding-bottom: 50px;
  position: relative;
  text-align: center;
}

.features__title {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 2px;
  margin-top: 0;
  margin-bottom: 66px;
}

.feature {
  display: flex;
  margin-bottom: 40px;
}

.feature-image {
  flex: 0 0 25%;
  max-width: 25%;
}

.feature-image__container {
  border: 1px solid $color-dark;
  border-radius: 50%;
  line-height: 64px;
  height: 64px;
  width: 64px;
}

.feature-description {
  flex: 0 0 75%;
  max-width: 75%;
  margin-top: 5px;
  margin-left: 10px;
  text-align: left;
}

.feature-description__title {
  font-size: 18px;
  line-height: 12px;
  margin-top: 0;
  margin-bottom: 10px;
}

.feature-description__text {
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
}
