.component {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.component__content {
  background-color: #F3F3F3;
  flex: 1 1 0;
  flex-basis: 0; // fixes firefox bug
  overflow-y: overlay;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    position: absolute;
    cursor: pointer;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #979797;
    border: 1px solid #979797;
    border-radius: 8px;
    height: auto;
    width: 2px;
    transition: background-color .3s;

    &:hover {
      background-color: #979797;
    }
  }

  &::-webkit-scrollbar-button {
    display: inline-block;
    height: 16px;
    width: 4px;
  }

  &::-webkit-scrollbar-track-piece {
    border: 0;
  }
}

.component__footer {
  flex: 0 1 auto;
}
