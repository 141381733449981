@import '../../../../constants/style';

.review {
  color: $color-dark;
  padding-bottom: 140px;
  padding-top: 88px;
  text-align: center;
}

.review__title {
  margin-top: 0;
  font-size: 32px;
  line-height: 40px;
}

.review__description {
  font-style: italic;
  font-size: 18px;
  margin: 0;

  &:after {
    background-color: $color-dark-blue;
    content: '';
    display: block;
    height: 1px;
    margin: 45px auto;
    width: 105px;
  }
}

.person__name {
  margin-top: 0;
  font-size: 16px;
  margin-bottom: 10px;
}

.person__info {
  margin-bottom: 30px;
  font-size: 16px;
}

.person__avatar {
  border: 1px solid $color-dark;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  line-height: 60px;
  margin-right: 20px;
  height: 60px;
  width: 60px;
  opacity: 0.5;
  transition: opacity .3s;

  &.active {
    opacity: 1;
  }
}