@import '../../../constants/style';

.side-modal-input-container {
  position: relative;

  .char-remaining {
    right: 0;
  }
}

.side-modal__input.settings__input {

  @media all and (min-width: 991px) {
    width: 100%;
  }
}

.MuiFormControl-root.settings__input--first {
  margin-bottom: 24px;
}

.settings__checkbox {
  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiTypography-body1 {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-right: auto;
  }

  .MuiSwitch-switchBase {
    color: #f1f1f1;
  }

  .MuiSwitch-track {
    background-color: #221f1f;
    opacity: 0.26;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: $color-orange;

    & + .MuiSwitch-track {
      background-color: $color-orange;
    }
  }
}

.limit-votes__line {
  background-color: #D8D8D8;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
}

.settings__export {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.settings-limit__input {
  @media all and (min-width: 992px) {
    width: 90px;
  }

  @media all and (max-width: 991px) {
    width: 90px;
  }
}

.side-modal-limit-container {
  display: flex;
  justify-content: space-between;
}

.side-modal__limit {
  min-width: 105px;
}

.side-modal__limit-label {
  color: rgba(0, 0, 0, 0.38);
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
}

.side-modal__limit-value {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.side-modal-limit-dots {
  margin-left: 10px;

  .add-dot {
    margin-right: 7px;
  }
}
