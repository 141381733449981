@import "../../../constants/style";

.stack-component {
  position: relative;
}

//.stack-component__stack::before {
//  content: ' ';
//  position: absolute;
//  top: 0;
//  left: $default-padding;
//  right: $default-padding;
//  height: $default-padding;
//  z-index: $base-z-index + $base-z-index-step * 2.5;
//}

//.stack-component--theme-light .stack-component__stack::before {
//  background: linear-gradient(to bottom, $color-lightest-gray, rgba(247,247,247,0));
//  z-index: 9;
//}
//.stack-component--theme-dark .stack-component__stack::before {
//  background: linear-gradient(to bottom, $color-light-gray, rgba(240,241,241,0));
//}
//.stack-component--theme-mint .stack-component__stack::before {
//  background: linear-gradient(to bottom, $color-white, rgba(252,252,252,0));
//}

.stack-component--theme-actions .component__content {
  background-color: #dadada;
}

.stack-component .stack-component__stack {
  padding: $default-padding;
  overflow: scroll;
}

@media #{$desktop} {
  .stack-component .stack-component__stack {
    padding: 16px 9px 16px 8px;
    overflow: scroll;
  }
}
