@import '../../../constants/style';

.MuiFormControl-root.account__input {
  display: block;
  &.account__input--first {
    margin-bottom: 5px;
  }

  &.account__input--last {
    .MuiInputBase-root {
     margin-bottom: 8px;
    }
  }

  .account__input-clear.account__input-clear--focused {

    &:before {
      background-color: rgba(0, 0, 0, 0.9);
    }

    &:after {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }


  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.38);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .MuiInputBase-root {
    width: 100%;
  }

  .MuiInput-input {
    caret-color: $color-orange;
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    padding-bottom: 1px;
    padding-top: 7px;
  }

  .MuiInput-underline:after {
    border-color: $color-orange;
  }

  .MuiInput-underline:before {
    border-color: rgba(0, 0, 0, 0.12);
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid $color-orange;
  }

  .account__input-clear {
    cursor: pointer;
    height: 24px;
    position: relative;
    width: 24px;

    &:before {
      border-radius: 0.75px;
      background-color: #B2B2B2;
      content: '';
      height: 19px;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: background-color 0.15s ease;
      width: 1.5px;
    }

    &:after {
      border-radius: 0.75px;
      background-color: #B2B2B2;
      content: '';
      height: 19px;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: background-color 0.15s ease;
      width: 1.5px;
    }
  }
}
