@import '../../../constants/style';


.modal__content.info-modal {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  max-width: 442px;
  text-align: center;
  position: relative;
  padding: 54px 12px 12px 12px;
}

.info-modal__title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
  margin-top: 0;
}

.info-modal__description {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
}

.info-modal__button {
  background: $color-orange;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  color: $color-white;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding: 21px;
  transition: opacity .3s linear;
  width: 100%;

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.8;
  }
}
