@import '../../constants/style';

.side-modal-wrapper {
  height: 100%;
  position: relative;
}

.side-modal {
  background-color: #F3F3F3;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  transition: margin .3s linear;
  margin-right: -452px;
  width: 452px;
  z-index: 1001;

  &.side-modal--active {
    margin-right: 0;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media all and (max-width: 595px) {
    margin-right: -100%;
    width: 100%;

    &.side-modal--active {
      margin-right: 0;
    }
  }
}

.side-modal__header {
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 23px 24px;
  position: fixed;
  top: 0;
  transition: box-shadow .15s ease-in-out;
  width: 100%;
  max-width: 452px;
  z-index: 999;

  &.side-modal__header--active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.side-modal-header-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.side-modal__title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.side-modal-close-wrapper {
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 24px;

  &:before {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1px;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 2px;
    width: 20px;
    transform: rotate(45deg);
  }

  &:after {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1px;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 2px;
    transform: rotate(45deg);
  }
}

.side-modal-content {
  padding-top: 70px;
}

.side-modal__box {
  background-color: $color-white;
  margin-top: 8px;
  padding: 24px;
}

.side-start-button {
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 12px 12px 12px;
  position: absolute;
}

.side-start {
  border: 0;
  background-color: $color-orange;
  border-radius: 8px;
  color: $color-white;
  cursor: pointer;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  transition: opacity .3s;
  padding: 21px 0;
  width: 100%;

  &:hover,
  &:focus {
    opacity: 0.8;
    outline: 0;
  }
}

.side-modal__input {
  font-family: 'Inter', sans-serif;
  width: 100%;

  @media all and (min-width: 991px) {
    width: 250px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.38);
  }

  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.38);
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  .MuiInput-underline:after {
    border-color: $color-orange;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: rgba(0, 0, 0, 0.12);
  }

  input {
    caret-color: $color-orange;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 24px;
    padding: 3px 0 2px 0;
  }
}
