@import '../../constants/style';

.modal__content-wrapper {
  overflow: auto;
  //margin-top: $large-margin;

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;

  width: 100%;
  height: 100%;
}

.modal__content {
  position: relative;
  background: $color-white;
  min-width: 200px;
  max-width: 860px;
  min-height: 100px;
  padding: $large-padding * 1.5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  margin: 64px;
  border-radius: 6px;
}

.modal__close-button {
  cursor: pointer;
  height: 28px;
  position: absolute;
  top: 21px;
  right: 22px;
  width: 28px;

  &:before {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1px;
    content: '';
    display: inline-block;
    height: 19px;
    width: 1.5px;
    position: absolute;
    top: 5px;
    left: 13px;
    margin: auto;
    transform: rotate(45deg);
  }

  &:after {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1px;
    content: '';
    display: inline-block;
    height: 1.5px;
    width: 19px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
  }
}

.modal__headline {
  align-self: flex-start;
  margin-top: $default-margin;
}

.modal__action-area {
  display: flex;
  justify-content: flex-end;

  margin-top: $large-margin
}

.modal__ack-button {
  align-self: flex-end;

  border: 0;
  background: none;
  outline: none;

  font-size: $theme-font-size;
  font-weight: bold;
  color: $color-orange;

  padding: $default-padding;
  transition: background .2s ease-in-out, color .15s ease-in-out;
}

.modal__ack-button:focus,
.modal__ack-button:hover {
  color: $color-white;
  background: $color-orange;
}
