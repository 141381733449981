@import '../../../../constants/style';

.prices {
  color: $color-dark;
  font-family: 'Inter', sans-serif;
  text-align: center;
  padding: 24px 0 48px 0;
}

.prices__title {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 2px;
  margin: 0 0 16px 0;
}

.prices__subtitle {
  font-size: 22px;
  line-height: 26px;
  margin: 0 0 48px 0;
}

.prices-list {
  display: flex;
  justify-content: space-between;
}

.price {
  background-color: $color-orange;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  color: $color-white;
  display: block;
  padding: 24px;
  text-align: left;
  text-decoration: none;
  transition: opacity .15s;
  width: 200px;

  &:hover,
  &:focus {
    opacity: 0.9;
  }
}

.price-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.price-features {
  padding-left: 20px;
}

.price__feature {
  margin-bottom: 8px;
}


