@import '../../../../constants/style';

.create-team-link {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 15px;
}

.create-team-link__text {
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 16px;
}

.create-team-link__link {
  align-items: center;
  background-color: $color-orange;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  line-height: 20px;
  height: 44px;
  justify-content: center;
  text-decoration: none;
  transition: opacity .15s;

  &:hover,
  &:focus {
    opacity: .8;
  }
}

.create-team-link__icon {
  display: inline-block;
  margin-right: 7px;
  height: 20px;
  width: 20px;
}
