@import './constants/style';

html,
body {
  height: 100%;
  overflow: hidden;

  -webkit-overflow-scrolling: touch;
}

button {
  margin: 0;
}

// fix for contentEditable blocks on safari
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

#root,
[data-reactroot] {
  height: 100%;
}

[data-reactroot] {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;

  font-family: $theme-font-family;
  font-size: $theme-font-size;

  color: $theme-color-font;
  background: darken($theme-color-background, 5%);
}

.close-button-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 22px;
  height: 28px;
  width: 28px;
}

.close-button {
  cursor: pointer;
  height: 28px;
  position: relative;
  width: 28px;

  &:before {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1px;
    content: '';
    display: inline-block;
    height: 19px;
    width: 1.5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1px;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
  }

  &:after {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1px;
    content: '';
    display: inline-block;
    height: 1.5px;
    width: 19px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
  }
}

.dark-toast {
  background: rgba(50,50,50,.9) !important;
  color: #fff !important;
  padding: 1em 2em !important;
  letter-spacing: .1em;
  font-size: .8em;
}

.dark-toast .toast__close-button,
.toast--danger .toast__close-button,
.toast--info .toast__close-button,
.toast--success .toast__close-button,
.toast--warning .toast__close-button {
  opacity: 0.7;
}

.dark-toast .toast__close-button,
.toast--danger .toast__close-button,
.toast--info .toast__close-button,
.toast--success .toast__close-button,
.toast--warning .toast__close-button {
  color: white;
}

.dark-toast .toast__close-button:hover,
.toast--danger .toast__close-button:hover,
.toast--info .toast__close-button:hover,
.toast--success .toast__close-button:hover,
.toast--warning .toast__close-button:hover {
  color: $theme-color-primary;
}

.dark-toast .dark-toast-progress,
.toast--danger .dark-toast-progress,
.toast--info .dark-toast-progress,
.toast--success .dark-toast-progress,
.toast--warning .dark-toast-progress {
  background: $theme-color-primary;
  opacity: 1;
}

.toast__close-button svg * {
  fill: $color-white;
}

.toast__close-button:focus svg *,
.toast__close-button:hover svg * {
  fill: $color-orange;
}

.toast--success {
  background: rgba(50,50,50,.9) !important;
  color: $color-green;
  padding: 1em 2em !important;
  letter-spacing: .1em;
  font-size: .8em;
}

.toast--warning {
  background: rgba(50,50,50,.9) !important;
  color: $color-warning;
  padding: 1em 2em !important;
  letter-spacing: .1em;
  font-size: .8em;
}

.toast--danger {
  background: rgba(50,50,50,.9) !important;
  color: $color-danger;
  padding: 1em 2em !important;
  letter-spacing: .1em;
  font-size: .8em;
}

.toast--info {
  background-color: $color-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #000000;
  padding: 24px 55px 24px 24px !important;
  letter-spacing: .1em;
  font-size: .8em;
}

.char-remaining {
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-size: 12px;
  position: absolute;
  right: 24px;

  &.char-remaining--last {
    top: 76px;
  }
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.container {
  box-sizing: border-box;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  box-sizing: border-box;
}

.col-sm-12, .col-sm-8, .col-sm-6, .col-md-4, .col-md-6 {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-6 {
  box-sizing: border-box;
  flex: 0 0 50%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 944px;
  }
}
