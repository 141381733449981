@import '../../../constants/style';

.username-box-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.username-box {
  background-color: $color-white;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 526px;
  padding: 30px;
  text-align: left;

  @media all and (min-width: 1200px) {
    min-width: 526px;
  }
}

.username__title {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 4px;
  margin-top: 0;
}

.username__intro {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 23px;
}

.username-input-container {
  position: relative;

  .char-remaining {
    right: 0;
  }
}

.username__submit {
  border: none;
  border-radius: 8px;
  background-color: $color-orange;
  color: $color-white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  transition: opacity .15s ease-in-out;
  padding: 22px 0;
  margin-top: 47px;
  width: 100%;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:disabled {
    cursor: auto;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
