@import '../../../constants/style';

.circle {
  width: 32px;
  height: 32px;
}

.circle__circle {
  fill: transparent;
  stroke: $color-dark;
  stroke-width: 2;
}