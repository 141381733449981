@import "../../constants/style";

.DataTableContainer {
  overflow: auto;
  border-left: 1px solid #dee3e7;
  border-right: 1px solid #dee3e7;
}

.DataTable {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  overflow: auto;
  min-width: 100%;
  background: #fff;

  &__headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    div {
      text-transform: uppercase;
    }

    input {
      padding: 10px 35px 10px 15px;
      border: none;
      outline: none;
      border-radius: 5px;
      width: 230px;
      font-size: 16px;
      background-image: url("./img/search.svg");
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 20px;
    }
  }

  &__headerColumn--sortable {
    display: flex;

    & > * {
      padding-right: 5px;
    }
  }

  &__headerRow {
    border-top: 1px solid #dee3e7;
    border-bottom: 1px solid #dee3e7;
    color: #022f49;
    font-size: 14px;
    // background-color: #818dd6;
    // color: white;

    th {
      font-weight: 600;
      height: 40px;
      overflow: visible;
      padding: 5px 15px;
      text-align: left;
      white-space: nowrap;
    }
  }

  &__itemRow {
    border-bottom: 1px solid #dee3e7;
    color: #566270;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: #f4f4f4;
      // background-color: #D6EFF9;
    }

    td {
      height: 40px;
      padding: 5px 15px;
      white-space: nowrap;
    }
  }

  &__empty {
    height: 100px;
    position: relative;

    td {
      padding: 40px 0;
      color: #acb3bc;
      border-bottom: 1px solid #dee3e7;
      border-left: 1px solid #dee3e7;
      border-right: 1px solid #dee3e7;
      display: block;
      width: calc(100vw - 82px);
      margin-left: -1px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
    }
  }

  &__navigation {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    div {
      align-self: center;
    }

    input {
      width: 25px;
      margin-right: 5px;
      text-align: right;
      font-size: 16px;
    }

    button {
      margin: 0 10px;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.Sort {
  cursor: pointer;
  position: relative;
  width: 10px;
}

.SortArrows a {
  position: absolute;
  display: flex;

  &#asc {
    color: black;
  }

  &#desc {
    color: black;
  }

  &:first-child {
    top: 0;
  }

  &:last-child {
    top: 8px;
  }
}
