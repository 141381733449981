@import '../../constants/style';

.register {
  background-color: $color-lightest-gray;
  text-align: center;
  min-height: 100vh;
}

.register-container {
  display: inline-block;
  margin: auto;
  padding-top: 55px;
  padding-bottom: 140px;
  text-align: left;
}

.register-container__logo {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 48px;
  display: inline-block;

  span {
    color: $color-orange;
  }
}

.register-container__logoContainer {
  text-decoration: none;
}

.auth-container {
  background: $color-white;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  height: 623px;
}

.auth-providers {
  //padding: 82px 92px 101px 92px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &>div {
    width: calc(100% - 24px);
  }

  .firebaseui-container {
    margin: 0 auto;
  }

  .firebaseui-idp-list {
    margin: 0;
  }

  .firebaseui-list-item {
    margin-bottom: 16px;
  }

  .firebaseui-idp-button {
    background: $color-white !important;
    border: 1px solid rgba(0,0,0,0.06);
    border-radius: 8px;
    box-shadow: none;
    max-width: 343px;
    padding: 19px 0 19px 24px;
    width: 343px;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-white !important;
      box-shadow: none;
    }
  }

  .firebaseui-idp-google {
    .firebaseui-idp-icon {
      filter: none;
    }
  }

  .firebaseui-idp-twitter {
    .firebaseui-idp-icon {
      filter: invert(15%) sepia(39%) saturate(6756%) hue-rotate(178deg) brightness(97%) contrast(92%);
    }
  }

  .firebaseui-idp-icon {
    filter: invert(1);
  }

  .firebaseui-idp-text {

    &.firebaseui-idp-text-long,
    &.firebaseui-idp-text-short {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }

}

.auth-providers__title {
  color: #000000;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 39px;
  margin-top: 0;
  margin-bottom: 47px;
}

.auth-image-container {
  align-items: center;
  background-color: $color-orange;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth-image__title {
  color: $color-white;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  margin-top: 0;
  margin-bottom: 22px;
  text-align: center;
}

.auth__image {
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  height: auto;
  width: calc(100% - 107px);
}

@media all and (min-width: 1200px) {
  .register-container {
    width: 1077px;
  }

  .auth-providers {
    width: 527px;
  }

  .auth-image-container {
    width: 550px;
  }

}

@media all and (max-width: 1199px) {
  .register {
    padding: 0 24px;
  }

  .register-container {
    width: 100%;
  }

  .auth-providers {
    flex: 0 0 50%;
  }

  .auth-image-container {
    flex: 0 0 50%;
  }
}

@media all and (max-width: 991px) {
  .auth-providers {
    flex: 0 0 100%;

    .firebaseui-idp-button {
      max-width: none;
      width: 270px;
    }
  }

  .auth-image-container {
    display: none;
  }

  //.auth__image {
  //  width: calc(100% - 40px);
  //}
}

@media all and (max-width: 700px) {
  .register {
    background-color: $color-white;
    padding: 0;
  }

  .register-container {
    padding-top: 0;
  }

  .register-container__logo {
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 0;
    padding: 23px 16px;
  }

  .auth-container {
    border-radius: 0;
    display: block;
  }

  .auth-image-container {
    display: none;
  }

  .auth-providers {
    display: block;
    padding: 31px 16px 71px 16px;

    &>div {
      width: 100%;
    }

    .firebaseui-container {
      margin: auto;
    }

    .firebaseui-idp-button {
      width: 100%;
    }
  }

  .auth-providers__title {
    margin-bottom: 16px;
  }

  .firebaseui-card-content {
    padding: 0 24px;
  }
}

@media all and (max-width: 480px) {

  .auth-providers > div > div {
    max-width: 100%;
  }

  .auth-providers {
    .firebaseui-card-content {
      padding: 0;
    }
  }
}


