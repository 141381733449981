@import "../../constants/style";

$card-z-index: $base-z-index + $base-z-index-step * 2;
$card-font-size: 14px;
$card-line-height: 24px;
$icon__width: 24px;

.card__root {
  position: relative;

  width: 100%;
  height: 100%;
  z-index: 1;
}

.card {
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
  overflow: hidden;
  padding: 18px 24px 22px 24px;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  z-index: $card-z-index;
}

.card:hover {
  transform: scale(1.02);
}

.card--focused {
  transform: scale(1.03);
  box-shadow: 0 $base-unit * 4 $base-unit * 8 0
    transparentize($color-dark-gray, 0.85);
  max-height: 200px;
}

.card--drophover {
  border: 1px solid $color-orange;
}

.card-group {
  background-color: #ffffff;
  border: 3px dashed $color-orange;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-sizing: border-box;
  color: $color-orange;
  display: none;
  left: 0;
  height: 100%;
  line-height: 1;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.card-group__text {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.card--disabled {
  background-color: transparent;
  border: 1px dashed $color-dark;
  position: relative;

  .card-plus {
    border: 3px solid $color-green;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    visibility: visible !important;

    &:after {
      background-color: $color-green;
      border-radius: 2px;
      content: "";
      width: 15px;
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:before {
      background-color: $color-green;
      border-radius: 2px;
      content: "";
      height: 15px;
      width: 3px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  & > * {
    visibility: hidden;
  }
}

.card--placeholder {
  visibility: hidden;
}

.card__selection-area:hover .card__admin-buttonbar {
  display: inherit;
}

.card__admin-buttonbar {
  position: absolute;
  top: $base-unit * 3;
  right: $base-unit * 5;
  display: none;

  margin: 0;
  padding: 0;

  z-index: $base-z-index + $base-z-index-step * 3;
}

.card__admin-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.card__admin-button:hover > .card__admin-button-icon > svg g {
  fill: $color-dark-gray;
}

.card__admin-button:not(:first-child) {
  margin-left: $base-unit * 2;
}

.card__admin-button-icon {
  width: $icon__width;
  height: $icon__width;
}

.card__admin-button-icon > svg {
  width: $icon__width;
  height: $icon__width;
}

.card__admin-button-icon g {
  fill: $color-alternative-gray;
}

.card--stacked {
  margin: 1em;
}

.card__author-image {
  flex: 0;
  width: $theme-icon-width;
  height: $theme-icon-height;
  border: 0;
  border-radius: 50%;
}

.card__content {
  box-sizing: content-box;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  height: 72px;
  margin: 0;
  max-width: 95%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  text-overflow: ellipsis;
  padding: 0;
  white-space: pre-wrap;

  div {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical !important;
    display: -webkit-box;
  }
}

.card__settings {
  position: absolute;
  right: 12px;
  top: 22px;

  .dd-menu-items {
    width: 98px;

    ul {
      background: #ffffff;
      border: 1px solid rgba(44, 44, 49, 0.06);
      border-radius: 8px;
      box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
    }
  }
}

.card__settings-icon {
  background-color: $color-white;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 24px;
  width: 24px;
  z-index: 1;

  span {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
    height: 4px;
    width: 4px;
  }
}

.card__settings-item {
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;

  &.card__settings-item--first {
    padding-top: 15px;
    padding-bottom: 5px;
  }

  &.card__settings-item--last {
    padding-bottom: 15px;
    padding-top: 5px;
  }

  &.card__settings-item--only {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.card__content > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.card__more-content-indicator {
  background-color: #ffffff;
  color: $color-orange;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 0;
  bottom: 0;

  height: $card-line-height;
  width: 100%;

  text-align: left;
}

.card__text--edit-mode {
  width: 100%;
}

.card__footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  padding: 2px;
}

.card__footer-spacer {
  flex: 1 100 100%;
}

.card__details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  margin-right: 1em;
  margin-left: 1em;
}

.card__stack {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.card__in-stack {
  box-sizing: border-box;
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  // 100% - margin of $base-unit*2 on both sides of the stacked card
  width: calc(100% - #{$base-unit * 2});
  height: 100%;

  right: $base-unit;
  bottom: -6px;

  z-index: $card-z-index - $base-z-index-step;
}

.card__author {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;

  flex: 1 1 100%;
}

.card__avatar.avatar {
  margin-right: 8px;
  font-size: 16px;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  min-width: 32px;
  width: 32px;
}

.card__assignedUser {
  margin-left: auto;
  user-select: none;
}

.card__notAssigned {
  font-size: 10px;
  text-transform: uppercase;
}
