@import "../../constants/style";

.dashboard {
  background-color: $color-lightest-gray;
  min-height: 100vh;

  @media all and (min-width: 1200px) {
    .container {
      max-width: 1110px;
    }
  }
}

.StatisticsPage {
  padding: 40px;

  &__table {
    margin-bottom: 40px;
  }

  &__calculations {
    margin-bottom: 20px;
  }
}
