@import '../../constants/style';

.timer-button__menu.dd-menu {
  height: 36px;
  margin-left: 0;
  position: relative;
}

.timer-button__menu .dd-menu-items {
  background-color: #ffffff;
  position: absolute;
  top: calc(100% + 10px);
  width: 150px;
  z-index: 1000;
  left: -50px;

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
}

.timer-button__menu-item {
  color: #000000;
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  padding: 5px 20px;
  text-align: center;
  transition: background-color .3s;
}

.timer-button__menu-item-custom {
  box-sizing: border-box;
  position: relative;
  padding: 5px 20px;
  width: 150px;
}

.timer-button__menu-item-custom svg {
  height: 20px;
  width: 20px;
}

.timer-button__input {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  box-sizing: border-box;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80px;

  &:hover,
  &:focus {
    outline: none;
  }
}

.timer-button__submit {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 4px;
}

.dd-menu li.timer-button__menu-item:hover {
  background-color: $color-orange !important;
}
