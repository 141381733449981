@import "../../constants/style";

.subnav {
  font-family: "Inter", sans-serif;
  margin-bottom: 26px;
  padding-top: 64px;

  @media all and (max-width: 420px) {
    margin-bottom: 24px;
    padding-top: 16px;
  }
}

.subnav-top {
  margin-bottom: 38px;

  @media all and (max-width: 420px) {
    flex-direction: column-reverse;
    margin-bottom: 29px;
  }
}

.subnav-search {
  display: inline-block;
  position: relative;

  @media all and (max-width: 420px) {
    display: block;
  }
}

.subnav-search__input {
  font-family: "Inter", sans-serif;
  background-color: #ffffff;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 15px 10px 21px;
  width: 284px;

  @media all and (max-width: 991px) {
    width: 100%;
  }

  &:focus {
    outline: 0;
  }
}

.subnav-search__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
  width: 24px;
  transition: opacity 0.3s;

  svg {
    height: 24px;
    width: 24px;
    * {
      fill: #b2b2b2;
    }
  }

  &.subnav-search__icon--hide {
    svg {
      opacity: 0;
    }
  }
}

.subnav-top {
  .subnav-create-new {
    &:after {
      content: "";
      display: inline-block;
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      position: fixed;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      z-index: -1;
    }

    @media all and (max-width: 420px) {
      bottom: 16px;
      flex: 0 0 100%;
      position: fixed;
      width: 100%;
      z-index: 9;
    }
  }
}

.subnav__link {
  background-color: $color-orange;
  border-radius: 8px;
  color: #ffffff;
  float: right;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 14px 17px 14px 39px;
  position: relative;
  text-decoration: none;
  transition: opacity 0.3s linear;

  @media all and (min-width: 421px) and (max-width: 767px) {
    padding: 14px 5px 14px 20px;

    &:before {
      left: 10px !important;
    }

    &:after {
      left: 4px !important;
    }
  }

  @media all and (max-width: 420px) {
    box-sizing: border-box;
    float: none;
    margin-bottom: 0;
    min-width: 180px;
    width: 100%;
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:before {
    background-color: #ffffff;
    border-radius: 10px;
    content: "";
    display: inline-block;
    position: absolute;
    left: 21px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 14px;
    width: 2px;
  }

  &:after {
    background-color: #ffffff;
    border-radius: 10px;
    content: "";
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 2px;
    width: 14px;
  }
}

.subnav-board-type {
  margin-bottom: 40px;
  overflow-y: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: 420px) {
    margin-bottom: 29px;
  }
}

.subnav-board-type-container {
  display: flex;
}

.subnav__board-type {
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 25px;
  min-width: 115px;
  transition: font-weight 0.1s linear;

  &--lastChild {
    color: red;
    margin-left: auto;
  }

  &--teamBoards {
    margin-right: 60px !important;
  }

  @media all and (max-width: 420px) {
    margin-right: 24px;
  }

  &.subnav__board-type--last {
    margin-right: 5px;
  }

  &.subnav__board-type--active {
    font-weight: 700;
  }
}

.subnav__main-title {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.subnav__dropdown {
  justify-content: flex-end;

  @media all and (max-width: 575px) {
    justify-content: left;
    margin-left: 0;
  }

  &.dd-menu {
    .dd-menu-items {
      top: 30px;

      @media all and (max-width: 575px) {
        left: 0;
        right: auto;
        top: 20px;
      }

      ul {
        border-radius: 8px;
        border: 1px solid #f3f3f3;
        padding: 14px 28px 17px 19px;

        .subnav__dropdown-item {
          color: #000000;
          cursor: pointer;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;

          &.subnav__dropdown-item--first {
            margin-bottom: 17px;
          }

          &.subnav__dropdown-item--active {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.subnav__dropdown-button {
  color: #000000;
  cursor: pointer;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 2px;
  margin-left: auto;
  padding-right: 22px;
  position: relative;

  &:before {
    background-color: #000000;
    border-radius: 20px;
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 1.4px;
    position: absolute;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
    right: 10px;
    transition: transform 0.2s linear;
    width: 8px;
  }

  &:after {
    background-color: #000000;
    border-radius: 20px;
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 1.4px;
    position: absolute;
    transform: rotate(-45deg);
    top: 0;
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
    right: 4px;
    transition: transform 0.2s linear;
    width: 8px;
  }

  &.subnav__dropdown-button--active {
    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
      right: 5px;
    }
  }
}

.subnav-search__loader {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin-bottom: auto;
  margin-top: auto;
  height: 24px;
  width: 24px;
}

.search__close {
  display: inline-block;
  cursor: pointer;
  height: 24px;
  width: 24px;

  &:before {
    background-color: #000000;
    border-radius: 2px;
    content: "";
    display: inline-block;
    position: absolute;
    top: 2px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 19px;
    transform: rotate(45deg);
    width: 1.5px;
  }

  &:after {
    background-color: #000000;
    border-radius: 2px;
    content: "";
    display: inline-block;
    position: absolute;
    top: 2px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    transform: rotate(135deg);
    height: 19px;
    width: 1.5px;
  }
}

.search__loader {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 1.6px solid $color-orange;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-orange transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
