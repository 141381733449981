@import '../../../../constants/style';

.landing-header {
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  color: $color-dark;
  display: flex;
  font-family: $theme-font-family;
  justify-content: space-between;
  height: 88px;
  padding-left: 40px;
  padding-right: 40px;
  position: fixed;
  width: 100%;
  z-index: 9;
}

.landing__logo {
  height: 34px !important;
  width: 162px !important;
}

.landing__company-name {
  margin-left: 5px;
  font-weight: 700;
}

.landing-navigation {
  align-items: center;
  display: flex;
  height: 40px;
}

.landing-navigation__link {
  cursor: pointer;
  margin-left: 25px;
  font-size: 16px;
  position: relative;

  &:after {
    background-color: $color-dark;
    display: block;
    content: '';
    height: 1px;
    position: absolute;
    bottom: -4px;
    transition: width .3s;
    width: 0;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.landing-navigation__button {
  border: 1px solid $color-dark;
  border-radius: 3px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  margin-left: 25px;
  line-height: 38px;
  padding: 0 25px;
  text-decoration: none;
}

.landing-header__border {
  background-color: $color-dark;
  bottom: 0;
  height: 2px;
  left: 0;
  position: absolute;
  width: 0;
  transition: width .3s;
}
