@import '../../constants/style';

$header__control-height: 66px;

.header__control {
  position: relative;

  background: $theme-color-secondary;
  padding: 0 $default-margin 0 0;
  height: $header__control-height;

  display: flex;

  align-items: center;
  justify-content: space-between;

  @media all and (max-width: 595px) {
    padding: 0 5px 0 0;
  }
}

.header__user-management {
  display: flex;
}

.header-third {
  align-items: center;
  display: flex;
  flex: 0 0 33.33%;
  height: 100%;
  width: 33.33%;

  @media all and (max-width: 399px) {
    flex: inherit;
    width: inherit;
  }
}

.header-phase-container {
  justify-content: center;
}

.header__control-users {
  display: flex;
  justify-content: flex-end;
}

.header__control-logo {
  flex-basis: 66px;
  margin-right: $default-margin;
}

@media #{$tablet} {
  .header__control {
    padding: 0 26px 0 0;
  }

  .header__control-logo {
    display: block;
    justify-content: center;
    flex-basis: 150px;
    margin-right: $large-margin;
  }
}
