@import '../../constants/style';

.side-modal-box__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  color: rgba(0,0,0,0.87);
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 24px;
}

.team-members-list {
  margin-top: 24px;
  margin-bottom: 4px;

  &.team-members-list--full {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 0;
    padding-bottom: 24px;
  }
}

.team-member {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.team-member-user {
  display: flex;
}

.team-member-user__avatar {
  height: 36px;
  line-height: 36px;
  margin-right: 8px;
  width: 36px;
}

.team-member-user__name {
  font-size: 14px;
  color: rgba(0,0,0,0.90);
  font-weight: 400;
  text-align: left;
  line-height: 36px;
  max-height: 36px;
  overflow-x: hidden;
}

.team-member-user__link {
  width: 24px;
  padding-top: 2px;
  margin-right: 8px;
  cursor: pointer;
}

.team-member-action {
  align-items: center;
  display: flex;
}

.team-member__admin {
  background: $color-orange;
  border-radius: 4px;
  border: none;
  color: $color-white;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 19px;
  width: 48px;

  &.team-member__admin--active {
    cursor: pointer;
  }
}

.team-member__user-action {
  align-items: center;
  background-color: $color-orange;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  margin-right: 8px;
  justify-content: center;
  height: 24px;
  width: 24px;
  outline: 0;
  opacity: 1;
  transition: opacity .15s ease;

  &:before {
    background-color: #FFFFFF;
    border-radius: 1px;
    content: '';
    display: inline-block;
    height: 1.5px;
    width: 10px;
  }

  &:focus,
  &:hover {
    opacity: 0.8;
    outline: none;
  }

  &:disabled {
    background-color: silver;
    cursor: not-allowed;
  }
}

.team-members-upgrade {
  margin-top: 15px;
}

.team-members-upgrade-message {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0,0,0,0.90);
  text-align: left;
  line-height: 24px;
  padding-bottom: 22px;
}

.team-members-toggle-message {
  margin-top: 14px;
  font-size: 14px;
}
