@import '../../../constants/style';

.keyboard-navigation-hint {
  display: flex;

  color: $color-white;
  font-size: $theme-font-size;
  line-height: 32px;
}

.keyboard-navigation-hint > * {
  align-self: center;
}

.keyboard-navigation-hint > span + span {
  padding-left: $base-unit*2.5;
}

