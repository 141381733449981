@import '../../../constants/style';

.side-share {
  padding: 24px 24px 0 24px;
}

.side-share-link-wrapper {
  margin-bottom: 24px;
  padding-top: 5px;
}

.side-share-link__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
  margin-top: 0;
}

.side-share__link {
  background-color: #ffffff;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.38);
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  text-decoration: none;
  padding: 15px 15px;
  position: relative;
}

.side-share__icon {
  background-color: $color-white;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 13px;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
  width: 24px;

  svg {
    height: 18px;
    width: 19px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}

.share-modal__text {
  display: inline-block;
  overflow: hidden;
}

.side-share__invite-button {
  background-color: $color-orange;
  border: 0;
  border-radius: 8px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 14px;
  padding: 21px;
  text-align: center;
  text-transform: uppercase;
  transition: opacity .3s;
  width: 100%;

  &:hover,
  &:focus {
    outline: 0;
  }

  &:hover:not(:disabled) {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
}

.side-share__copy-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  transition: opacity .3s;
  opacity: 1;

  &.side-share__copy-text--hidden {
    opacity: 0;
  }

  &.side-share__copy-text--error {
    color: $color-danger;
  }
}

.share-modal__icon {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 999;

  svg {
    width: 19px;
    height: auto;
  }
}

.side-share-link__input {
  border: 1px solid $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  padding: 9px 12px;
  width: 100%;

  &.side-share-link__input--incorrect {
    border: 1px solid $color-danger;
  }

  &:focus {
    outline: 0;
  }
}

.side-share-input__textarea {
  background: $color-white;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.90);
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  min-height: 152px;
  resize: none;
  line-height: 24px;
  padding: 12px 16px;
  transition: border-color .15s;
  overflow: hidden;
  width: 100%;
  margin-bottom: 16px;

  &:focus,
  &:hover {
    border-color: $color-orange;
    outline: 0;
  }

  &.side-share-input__textarea--error {
    border-color: $color-danger;
  }
}

.side-share__message {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 10px;
}
