.avatar {
  display: inline-block;

  margin: 0;

  width: 32px;
  height: 32px;
  border-radius: 100%;

  line-height: 32px;
  font-size: 16px;

  vertical-align: middle;
  text-align: center;
}

.avatar--faded {
  filter: brightness(50%);
}

$avatar-colors: (
  'red': #d50000 #ffebee,
  'pink': #d81b60 #fff,
  'purple': #7b1fa2 #e1bee7,
  'deep-purple': #311b92 #d1c4e9,
  'indigo': #3949ab #c5cae9,
  'blue': #2962ff #fff,
  'light-blue': #4fc3f7 #311b92,
  'cyan': #26c6da #004d40,
  'teal': #1de9b6 #004d40,
  'green': #2e7d32 #004d40,
  'light-green': #aed581 #1b5e20,
  'lime': #d4e157 #00695c,
  'yellow': #ff0 #795548,
  'amber': #ffca28 #4e342e,
  'orange': #fb8c00 #212121,
  'deep-orange': #ff3d00 #212121,
  'brown': #795548 #efebe9,
  'grey': #616161 #f5f5f5,
  'blue-grey': #455a64 #eceff1,
) !default;

@mixin avatar-color($bg-color, $text-color, $suffix) {
  .avatar--#{$suffix} {
    background: $bg-color;
    color: $text-color;
  }
}

@each $suffix, $colors in $avatar-colors {
  @include avatar-color(nth($colors, 1), nth($colors, 2), $suffix);
}

