@import '../../../constants/style';

.modal__content.plan-exceed-modal {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  border-radius: 8px;
  background-color: $color-white;
  box-sizing: border-box;
  max-width: 442px;
  padding: 23px 12px 12px 12px;

  .modal__close-button {
    &:before {
      top: 0;
      left: 1px;
      bottom: 1px;
      right: 0;
      margin: auto;
    }

    &:after {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      margin: auto;
    }
  }
}

.plan-exceed-modal__title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 31px;
  margin-bottom: 12px;
  text-align: center;
}

.plan-exceed-modal__description {
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
  padding: 0 20px;
  text-align: center;
}

.plan-exceed-modal__button {
  background-color: $color-orange;
  border: 0;
  border-radius: 8px;
  color: $color-white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  display: block;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;
  text-decoration: none;
  transition: opacity .15s ease;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
}
