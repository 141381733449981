@import "../../../constants/style";
@import "../Card";

$icon__width: $theme-font-size * 2;
$card-details-z-index: $card-z-index + $base-z-index-step;

.card-details__backdrop {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: $card-details-z-index;
}

.card_details__card {
  @extend .card;
  height: auto;
  overflow: visible;
  min-width: 300px;
  width: calc(100vw - 32px);
  position: relative;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.card-details__card-text {
  @extend .card__content;
  overflow: inherit;
  height: auto;
  min-height: 4.5em;
  cursor: text;

  padding: 0;
  margin: 0 0 15px 0;
  transition: outline 0.3s ease-in-out;
  outline: solid 2px transparent;
  outline-offset: $base-unit * 1.5;
}

.card-details__card-text:focus {
  outline: solid 2px $color-alternative-gray;
}

.card-details__card-text > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.card-details__content-text--edit-mode {
  box-sizing: border-box;

  height: 9.5em;
  width: 100%;
  padding: 1em;
  font-size: $theme-font-size;
  line-height: 1.5;
}

.card-details__options {
  display: none;
  list-style-type: none;
  margin: 0 0 0 10px;
  padding: 0;

  position: absolute;
  top: 0;
  right: -44px - $default-margin;
}

@media #{$tablet} {
  .card-details__options {
    display: block;
  }
}

.card-details__option {
  box-sizing: border-box;
  display: block;

  .icon-button {
    margin-left: 10px;
  }
}

.card-details__option + .card-details__option {
  margin-top: $small-margin;
}

.card-details__stack {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.card-details__stack-item {
  margin-top: 1em;
}

.card-details__delete-icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
}

.card-details__delete-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
  align-self: center;
}

.card-details__userList {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 32px;
  background-color: white;
  padding: 12px 0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.card-details__singleUserInLinst {
  padding: 12px 24px;

  &:hover {
    background-color: lightblue;
    cursor: pointer;
  }
}

.card__notAssigned--clickable {
  color: #0052cc;
  text-decoration: underline;
  cursor: pointer;
}

.card__unassignUser {
  color: red;
  position: relative;
  top: -12px;
}

.u-pointer {
  cursor: pointer;
}

.card-details-container {
  display: flex;
  border-radius: 8px;
  height: 100%;

  .card_details__card {
    border-radius: 8px 0 0 8px;
    border-right: 1px solid silver;
    width: 40%;

    @media only screen and (max-width: 900px) {
      min-width: 0;
    }

    &:hover {
      transform: none;
    }
  }
}

.card-details-add-action-button {
  min-width: 300px;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 500px) {
    display: none;
  }
}

.card-details-wrapper {
  left: 20px;
  right: 20px;
  height: 80vh;

  @media only screen and (max-width: 900px) {
    left: 100px;
    right: 100px;
  }

  .portal__stop-propagation {
    width: 90%;
    height: 85%;
  }

  .stack-component__stack {
    pointer-events: none;

    @media only screen and (max-width: 900px) {
      grid-template-columns: auto !important;
    }

    .card__settings {
      display: none;
    }

    .card__notAssigned--clickable {
      text-decoration: none;
      color: black;
    }
  }

  .card {
    &:hover {
      transform: none;
    }
  }

  .card-details__userList {
    max-height: 15vh;
    overflow: scroll;
  }

  .board__column {
    border-radius: 0 8px 8px 0;
  }
}
