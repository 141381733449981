@import "../../constants/style";

.dashboard-board-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  margin-bottom: 24px;
  padding: 20px 24px;
  position: relative;
  transition: transform 0.3s linear;
  text-align: left;

  &:hover,
  &:focus {
    transform: scale(1.02);
    outline: 0;
  }
}

.dashboard-board__link {
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  height: 100%;
}

.card-dropdown {
  &.dd-menu {
    position: absolute;
    right: 9px;
    top: 10px;

    .card-dropdown__icon {
      color: #b2b2b2;
      cursor: pointer;
      font-size: 23px;
      transform: rotate(90deg);
    }
  }
}

.dashboard-board__header {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 2px;
  margin-top: 0;
  max-height: 44px;
  overflow: hidden;
}

.dashboard-board__secure {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 2px;
}

.dashboard-board__secure-icon {
  display: inline-block;
  margin-right: 5px;

  &.dashboard-board__secure-icon--private {
    svg {
      height: 12px;
      width: 10px;
    }
  }

  svg {
    height: 12px;
    width: 12px;

    * {
      fill: #999999;
    }
  }
}

.dashboard-board__author {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.card-dropdown {
  &.dd-menu {
    .dd-menu-items {
      top: 20px;

      ul {
        background: $color-white;
        border: 1px solid rgba(44, 44, 49, 0.06);
        border-radius: 8px;
        box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        padding: 15px 17px 7px 17px;

        li {
          color: #000000;
          cursor: pointer;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          min-width: 104px;
          margin-bottom: 8px;
          position: relative;

          .dashboard-board__user-list-icon {
            display: inline;
            padding: 0;
            position: absolute;
            right: 0;

            svg {
              height: 16px;
              width: 12px;
            }
          }
        }

        .dashboard-board__delete-button {
          color: $color-danger;
        }
      }
    }
  }
}

.dashboard-board__user-list {
  text-decoration: none;
}

.dashboard-board__footer {
  display: flex;
  justify-content: space-between;
}

.dashboard-board__footer-left {
  cursor: pointer;
  display: flex;
}

.dashboard-board__footer-right {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;

  @media all and (max-width: 991px) {
    position: absolute;
    right: 24px;
  }
}

.dashboard-board__users-more {
  align-items: center;
  background-color: $color-orange;
  border: 2px solid $color-white;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  position: relative;
  margin-left: -12px;
  height: 32px;
  width: 32px;

  svg {
    height: 14px;
    width: 14px;
  }
}

.dashboard-board__avatar {
  margin-left: -12px;

  &.dashboard-board__avatar--first {
    margin-left: -2px;
  }

  .avatar {
    border: 2px solid #ffffff;
  }
}

.card-dropdown.dd-menu.dd-menu-right .dd-menu-items {
  right: 7px;
  top: calc(100% - 23px);
}
