@import '../../../../constants/style';

.header__logo {
  align-self: center;
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;

  span {
    color: $color-orange;
  }

  @media all and (max-width: 991px) {
    font-size: 22px;
  }

  @media all and (max-width: 767px) {
    display: none;
  }
}
