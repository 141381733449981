// base constants
$base-unit: 4px;
$base-z-index: 100;
$base-z-index-step: 10;

// color constants
$color-dark: #333333;
$color-white: #FFFFFF;
$color-green: #00E7C2;
$color-orange: #FA6400;
$color-dark-blue: #9CAFC3;
$color-dirty-gray: #F3F5F8;

// new font colors
$color-white: #FFFFFF;
$color-dark-gray: #27322F;
$color-middle-gray: #5F746E;
$color-alternative-gray: #C8CBCA;
$color-mint: #00E7C2;
$color-dark-mint: #00AD91;
$color-darkest-mint: #3EB399;
$color-gray: #E4E6E5;
$color-light-gray: #F0F1F1;
$color-lightest-gray: #F3F3F3;
$color-stone-gray: #a4a8a7;
$color-orange: #FA6400;
$color-dark-blue: #9CAFC3;
$color-dirty-gray: #F3F5F8;
$color-danger: #E02020;
$color-warning: #ffc107;
$color-info: #17a2b8;


// theme colors
$theme-color-background: $color-white;
$theme-color-font: $color-dark;
$theme-color-primary: $color-orange;
$theme-color-secondary: $color-white;
$theme-color-disabled: $color-gray;

// font options
$theme-font-family: 'Inter', sans-serif;
$theme-font-size: $base-unit * 4;
$theme-line-height: $base-unit * 6;

// responsive
$tablet: "screen and (min-width : 768px)";
$desktop: "screen and (min-width : 1280px)";

// margins & paddings
$small-margin: $base-unit * 2;
$default-margin: $base-unit * 4;
$large-margin: 2*$default-margin;
$default-padding: $default-margin;
$large-padding: $large-margin;

// dimensions
$theme-icon-width: $base-unit*8;
$theme-icon-height: $base-unit*8;


// mixins
@mixin animate($property, $duration: 0.3s) {
  transition: $property $duration ease-in-out;
}
