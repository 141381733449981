@import "../../../../constants/style";

.teams {
  display: flex;
  flex: 1;

  @media all and (max-width: 760px) {
    width: 100%;
    margin-top: 80px;
    flex-direction: column;
  }
}

.teams-heading {
  position: relative;
}

.teams-content__empty {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 17px 24px;

  p {
    margin: 0;
  }
}

.teams-content {
  flex: auto;
  margin-right: 24px;

  @media all and (max-width: 760px) {
    width: 100%;
    margin-bottom: 30px;
  }
}

.teams-create__button {
  align-items: center;
  background-color: $color-orange;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 14px 0;
  min-width: 260px;
  transition: opacity .15s;

  &:focus,
  &:hover {
    opacity: 0.9;
    outline: 0;
  }
}

.teams-create__icon {
  display: inline-block;
  height: 16px;
  margin-right: 10px;
  position: relative;
  width: 14px;

  svg {
    height: 14px;
    width: 14px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.side-modal.teams-sidebar {
  width: 377px;

  .side-modal__header {
    width: 377px;
  }

  .side-modal-content {
    height: calc(100% - 70px);
  }
}
