@import '../../constants/style';

.general-menu {
  margin-right: 32px;

  &--mobile {
    display: none;

    @media only screen and (max-width: 991px) {
      display: block;
      width: 100%;
      padding: 12px 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  .general-menu {
    display: none;
  }
}

.general-menu--centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

.general-menu__input {
  text-align: center !important;
  display: flex;

  white-space: pre;
  text-decoration: none;
  font-size: inherit;
  color: $color-dark;

  min-width: 100px !important;
  max-width: 200px;

  .input__underline {
    z-index: 9;
  }
}

.general-menu-item__button {
  border: 1px solid #F0F0F0;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  transition: background-color .3s;
  position: relative;
  height: 36px;
  width: 36px;

  svg {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    //* {
    //  transition: fill .3s;
    //}
  }

  //&:hover {
  //  background-color: $color-orange;
  //
  //  svg * {
  //    fill: $color-orange;
  //    stroke: #ffffff;
  //  }
  //}

  &.general-menu-item__button--share {
    svg {
      height: auto;
      width: 19px;
    }
  }

  &.general-menu-item__button--delete {
    svg {
      height: auto;
      width: 16px;
    }
  }

  &.general-menu-item__button--timer {
    svg {
      height: auto;
      width: 18px;
    }
  }

  &.general-menu-item__button--settings {
    margin-right: 0;

    svg {
      height: auto;
      width: 20px;
    }
  }

  &.general-menu-item__button--export {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}


.general-menu-item__button-icon--danger svg {
  fill: #bb2124;
}

.general-menu-item__button--ready {
  cursor: pointer;
  transition: background-color .15s ease;

  svg {
    height: 16px;
    width: 16px;

    path {
      transition: fill .15s ease;
    }
  }

  &.general-menu-item__button--ready-active {
    background-color: $color-orange;

    path {
      fill: $color-orange;
    }
  }
}
