@import "../../constants/style";

.side-modal__box.team-invite {
  padding-bottom: 16px;
}

.team-invite__checkbox {
  margin-bottom: 18px;
  margin-top: 18px;
}

.team-invite-input {
  margin-bottom: 16px;
}

.team-invite-input__textarea {
  background: $color-white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.9);
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  min-height: 152px;
  resize: none;
  line-height: 24px;
  padding: 12px 16px;
  transition: border-color 0.15s;
  width: 100%;

  &:focus,
  &:hover {
    border-color: $color-orange;
    outline: 0;
  }

  &.team-invite-input__textarea--error {
    border-color: $color-danger;
  }
}

.team-invite__message {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 10px;
}
