@import '../../../constants/style';

.modal__content.all-users-content {
  border-radius: 8px;
  min-width: 443px;
  padding: 0;
}

.all-users-header {
  border-bottom: 1px solid #D8D8D8;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 23px 24px;
}

.all-users-header__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.all-users-list {
  margin: 0;
  padding: 24px;
  list-style: none;
  max-height: 330px;
  overflow: auto;
}

.all-users-list__item {
  margin-bottom: 24px;

  &.all-users-list__item--emailed {
    align-items: center;
    display: flex;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.all-users-list__item-avatar {
  display: inline-block;
  margin-right: 8px;

  .avatar {
    line-height: 36px;
    height: 36px;
    width: 36px;
  }
}

.all-users-list__item-info {
  display: inline-block;

  &.all-users-list__item-info--emailed {
    display: inline-flex;
    flex-direction: column;

    .all-users-list__item-name {
      line-height: 1;
    }
  }
}

.all-users-list__item-name {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.all-users-list__item-email {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  opacity: 0.6;
}
