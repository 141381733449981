@import '../../constants/style';

.dashboard {
  background-color: $color-lightest-gray;
  min-height: 100vh;

  @media all and (min-width: 1200px) {
    .container {
      max-width: 1110px;
    }
  }
}

.dashboard__archived {
  text-align: center;
}

.dashboard__archived-button {
  background-color: transparent;
  border: 0;
  color: #000000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  position: relative;
  margin-bottom: 40px;

  &.dashboard__archived-button--available {
    &:before {
      background-color: #000000;
      border-radius: 3px;
      content: '';
      display: block;
      width: 1px;
      height: 8px;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      right: -6px;
      transition: transform .3s;
    }

    &:after {
      background-color: #000000;
      border-radius: 3px;
      content: '';
      display: block;
      width: 1px;
      height: 8px;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      margin-bottom: auto;
      margin-top: auto;
      right: -11px;
      transition: transform .3s;
    }

    &.active {
      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }


  &:hover,
  &:focus {
    outline: 0;
  }
}
