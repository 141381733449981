@import '../../../../../constants/style';

.teams-button--primary {
  background: $color-orange;
  border-radius: 8px;
  border: 0;
  color: $color-white;
  cursor: pointer;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 14px 0;
  text-align: center;
  transition: opacity .15s;
  width: 100%;

  &:disabled {
    cursor: auto;
    opacity: 0.6;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }

  &:hover,
  &:focus {
    opacity: 0.8;
    outline: 0;
  }
}

.side-modal__box.team-update-box {
  padding-top: 19px;
  padding-bottom: 19px;
}

.team-update__link {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: rgba(0,0,0,0.90);
  line-height: 22px;
  text-decoration: none;
  position: relative;
}

.team-update__arrow {
  height: 12px;
  width: 6px;
  position: absolute;
  right: 3px;

  svg {
    height: 12px;
    width: 6px;
  }
}

.team-update-sharable-link {
  padding: 24px 24px 0 24px;
}

.pending-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.pending-user__btn {
  background-color: $color-orange;
  border: 0;
  border-radius: 4px;
  color: $color-white;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  line-height: 19px;
  margin: 0;
  padding: 0 4px;

  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
}
