@import '../../../constants/style';

.side-modal--participant {
  background-color: #F0F0F0;

  .side-modal-content {
    height: 100%;
  }
}

.participants-container {
  height: 100%;
}

.participants-box {
  background: #FFFFFF;
  margin-top: 1px;
  padding: 23px 24px;
}

.participant-box--last {
  height: 100%;
}

.participants-box__title {
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 24px;
}

.participants-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.participant {
  display: flex;
  justify-content: space-between;
}

.participant:not(:last-child) {
  margin-bottom: 24px;
}

.participant__name {
  color: rgba(0, 0, 0, 0.9);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 8px;
}

.participant__button {
  align-items: center;
  background-color: $color-orange;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 6px;
  height: 24px;
  width: 24px;
  outline: 0;
  opacity: 1;
  transition: opacity .15s ease;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:before {
    background-color: $color-white;
    border-radius: 1px;
    content: '';
    display: inline-block;
    height: 1.5px;
    width: 10px;
  }

  &.participant__button--add {
   &:after {
     background-color: $color-white;
     border-radius: 1px;
     content: '';
     display: inline-block;
     position: absolute;
     height: 10px;
     width: 1.5px;
   }
  }

  &:disabled {
    background-color: #EFEFEF;
    cursor: auto;

    &:after {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      opacity: 1;
    }
  }
}

.participant-avatar-container {
  .avatar {
    opacity: 0.2;
  }

  &.participant-avatar-container--active {
    .avatar {
      opacity: 1;
    }
  }
}

.participant-avatar {
  display: inline-block;
  position: relative;
}

.participant-avatar-ready {
  background-color: $color-orange;
  border: 1px solid $color-white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -1px;
  right: 0;
  height: 13px;
  width: 13px;
  z-index: 9;

  .participant-avatar-ready--checked {
    align-items: center;
    display: flex;

    svg {
      height: 4px;
      width: 6px;
    }
  }
}
