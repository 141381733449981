@import '../../constants/style';

.phase-menu {
  min-width: 150px;
}

.phase-menu__navigation {
  align-items: center;
  display: flex;
  justify-content: center;
}

.phase-menu__current-phase-name {
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-right: 20px;

  &.phase-menu__phase-phase-name--last {
    margin-right: 15px;
  }
}

.phase-menu__phase-index {
  background-color: #EFEFEF;
  border-radius: 50%;
  color: #000000;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  margin-right: 8px;
  text-align: center;
  transition: background-color .3s linear;
  height: 24px;
  width: 24px;

  &.phase-menu__phase-index--active {
    background-color: $color-orange;
    color: #ffffff;
  }
}

.phase-menu__dash {
  background-color: #D8D8D8;
  display: inline-block;
  margin-right: 19px;
  height: 25px;
  width: 1px;
}

.phase-menu__phase-button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  height: 16px;
  width: 10px;
  position: relative;

  &:before {
    background-color: #000000;
    border-radius: 2px;
    content: '';
    cursor: pointer;
    display: block;
    height: 2px;
    width: 10px;
  }

  &:after {
    background-color: #000000;
    border-radius: 2px;
    content: '';
    cursor: pointer;
    display: block;
    height: 2px;
    width: 10px;
  }

  &.phase-menu__phase-button--next {

    &:before {
      transform: rotate(45deg);
      position: absolute;
      top: 4px;
      right: 0;
    }

    &:after {
      transform: rotate(-45deg);
      position: absolute;
      bottom: 4px;
      right: 0;
    }
  }

  &.phase-menu__phase-button--previous {
    margin-right: 22px;
    &:before {
      transform: rotate(-45deg);
      position: absolute;
      top: 4px;
      left: 0;
    }

    &:after {
      transform: rotate(45deg);
      position: absolute;
      bottom: 4px;
      left: 0;
    }
  }
}

@media #{$tablet} {
  .phase-menu__phase-button {
    display: block;
  }
}

.phase-menu__phase-button:not(:disabled):hover {
  cursor: pointer;
}

.phase-menu__phase-button:disabled {
  &:before {
    background-color: #b2b2b2;
  }

  &:after {
    background-color: #b2b2b2;
  }
}

.phase-menu__phase-button:not(:disabled):hover,
.phase-menu__phase-button:not(:disabled):focus,
.phase-menu__phase-button:not(:disabled):active {
  &:before {
    background-color: $color-orange;
  }

  &:after {
    background-color: $color-orange;
  }
}

@media only screen and (max-width: 1199px) {
  .phase-menu__current-phase-name {
    display: none;

    &.phase-menu__phase-phase-name--active {
      display: inherit;
    }
  }
}

@media only screen and (max-width: 991px) {
  .phase-menu__current-phase-name {
    margin-right: 10px;
  }

  .phase-menu__dash {
    margin-right: 10px;
  }

  .phase-menu__phase-button.phase-menu__phase-button--previous {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .phase-menu__dash {
    display: none;
  }

  .phase-menu__phase-button.phase-menu__phase-button--previous {
    margin-right: 7px;
  }
}

@media screen and (max-width : 480px) {

  .phase-menu__current-phase-name {
    display: none;
  }
}
