@import '../../../../constants/style';

.header__back-button {
  align-items: center;
  background-color: $color-orange;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  line-height: 24px;
  margin-right: 24px;
  justify-content: center;
  cursor: pointer;
  min-width: 80px;
  height: 100%;
  width: 120px;
  transition: background-color .3s;
  z-index: 3;

  &:after {
    content: '';
    height: 2px;
    width: 10px;
    transform: rotate(45deg);
    display: block;
    background-color: #ffffff;
    bottom: calc(50% - 4px);
    position: absolute;
    left: 25px;
    border-radius: 4px;
  }

  &:before {
    content: '';
    height: 2px;
    width: 10px;
    transform: rotate(-45deg);
    display: block;
    background-color: #ffffff;
    top: calc(50% - 4px);
    position: absolute;
    left: 25px;
    border-radius: 4px;
  }


  @media all and (max-width: 767px) {
    margin-right: 5px;
    max-width: 70px;

    &:before {
      left: 10px;
    }

    &:after {
      left: 10px;
    }
  }

  &:hover {
    background-color: rgba(250, 100, 0, 0.8);
  }
}
