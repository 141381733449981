@import '../../../constants/style';

.timer-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 23px 15px 7px 15px;
}

.timer-options__item {
  background-color: $color-white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 48px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  flex: 0 0 calc(33.33% - 9px);
  line-height: 22px;
  margin-bottom: 16px;
  transition: background-color .3s, color .3s;
  padding: 16px;

  &.timer-options__item--middle {
    margin-left: 10px;
    margin-right: 15px;
  }

  &:hover,
  &:focus,
  &.timer-options__item--active {
    background-color: $color-orange;
    color: $color-white;
    outline: 0;
  }
}
