@import "../../constants/style";

.portal {
  position: fixed;

  top: -100px;
  left: -100px;
  right: -100px;
  bottom: -100px;

  overflow: auto;
  background: rgba(0, 0, 0, 0.8);

  backdrop-filter: blur(1px);

  z-index: $base-z-index + $base-z-index-step * $base-z-index;
}

.portal__close-button {
  position: absolute;
  top: $default-margin;
  right: $default-margin;

  background: none;
  outline: none;
  border: none;

  color: $color-white;
  font-weight: bold;
  text-transform: uppercase;

  z-index: 1;

  margin: 0;
  padding: 0;
}

@media #{$tablet} {
  .portal__close-button {
    position: absolute;
    top: 76px;
    right: 142px;
  }
}

.portal__close-button-icon {
  float: left;
}

.portal__close-button-text {
  cursor: pointer;
  display: inline-block;
  height: 24px;
  position: relative;
  width: 24px;

  &:before {
    background-color: $color-white;
    border-radius: 1.5px;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 1px;
    bottom: 0;
    margin: auto;
    height: 1.5px;
    transform: rotate(-45deg);
    width: 19px;
  }

  &:after {
    background-color: $color-white;
    border-radius: 1.5px;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(-45deg);
    height: 19px;
    width: 1.5px;
  }
}

//.portal__close-button:focus .portal__close-button-text,
//.portal__close-button:hover .portal__close-button-text {
//  text-decoration: underline;
//}

.portal__close-button svg * {
  fill: currentColor;
}

.portal__content {
  position: absolute;

  top: 100px;
  left: 100px;
  right: 100px;
  bottom: 100px;

  box-sizing: border-box;
  min-height: 100vh;

  padding: 72px $default-margin;

  // enable scroll of content
  overflow: auto;

  // horizontal centering of content
  display: flex;
  justify-content: center;
}

@media #{$tablet} {
  .portal__content {
    padding: 73px 45px;
  }
}

#portal {
  transition: background-color 0.3s;

  &.portal-side-modal-active {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
  }

  &.portal-account-signout {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
  }
}
