@import '../../constants/style';

.clickable-icon {
  align-items: center;
  border: 1px solid #F0F0F0;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 36px;
  position: relative;
  width: 36px;
  margin-right: 25px;
}

.clickable-icon__svg {
  svg {
    height: 18px;
    width: 18px;
  }
}

.clickable-icon__count {
  align-items: center;
  background-color: $color-orange;
  border-radius: 50%;
  color: $color-white;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  height: 20px;
  line-height: 1;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: -9px;
  width: 20px;
}
