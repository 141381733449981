@import '../../constants/style.scss';

.board-guard__denied-link {
  background-color: $color-dark;
  font-weight: bold;
  color: $color-white;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 2em;
  border-radius: 12px;
}

.board-guard__denied-link:focus,
.board-guard__denied-link:hover {
  background-color: $color-dark-gray;
}

.board-guard__not-team-part {
  display: flex;
  height: 100vh;
  align-items: center;
  line-height: 1.7;
  justify-content: center;
  text-align: center;
}

.board-guard__not-team-part-header {
  margin-bottom: 40px;
}
