@import '../../../../constants/style';

.contact {
  background-color: $color-dirty-gray;
  color: $color-dark;
  text-align: center;
  padding-top: 88px;
  padding-bottom: 40px;
}

.contact__title {
  margin-bottom: 50px;
  margin-top: 0;
  font-size: 32px;
  line-height: 40px;
}

.contact__description {
  margin-bottom: 15px;
  font-size: 18px;
  margin-top: 0;
}

.contact__form {
  text-align: left;
}

.contact__email-input {
  background-color: #ffffff;
  border: 1px solid $color-dark;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  margin-bottom: 15px;
  padding: 15px 18px;
  width: 100%;
}

.contact__message-input {
  background-color: #ffffff;
  border: 1px solid $color-dark;
  box-sizing: border-box;
  font-size: 16px;
  display: block;
  margin-bottom: 25px;
  padding: 15px 18px;
  resize: none;
  width: 100%;
}

.contact__send {
  border: 1px solid $color-dark;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
  padding: 15px 25px;
}