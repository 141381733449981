@import '../../constants/style';

.lock-banner {
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 999;
}

.lock-banner__content {
  background: $color-orange;
  border-radius: 48px;
  color: $color-white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 12px 24px;
}
