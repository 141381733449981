@import '../../constants/style.scss';

.new-board__logout-btn {
  cursor: pointer;

  outline: none;
  color: $color-orange;
  background: none;
  border: 2px solid $color-orange;
  border-radius: 3px;
  text-transform: uppercase;
  padding: 12px;
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  font-size: 15px;
  line-height: 13px;

  min-height: 50px;
  height: 50px;
}

.new-board__logout-btn:focus,
.new-board__logout-btn:hover {
  color: $color-orange;
  border-color: $color-orange;
}

.new-board__input {
  padding-right: 0;
  margin-bottom: $large-margin;
}

.new-board__action-area-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
}

.new-board__action-area-paragraph {
  margin-bottom: $large-margin;
}

.new-board__action-button {
  box-sizing: border-box;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 700;
  color: $color-white;
  padding: 9px 24px;
  height: 50px;

  background-color: $theme-color-primary;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: background 0.15s ease;
  cursor: pointer;
  text-transform: uppercase;

  margin-bottom: $default-margin;
}

.new-board__action-button:focus,
.new-board__action-button:hover {
  background: $color-orange;
}

.new-board__action-area-header {
  margin-bottom: $large-margin;
}

.new-board__access-mode-selection {
  margin-bottom: 16px;
}

@media #{$tablet} {
  .new-board__action-area {
    min-width: 300px;
    max-width: 420px;
  }
}

.new-board__action-area-content .dd-menu {
  margin-left: 0;

  .dd-menu-items {
    top: 42px;
    width: 100%;
  }
}

.new-board__back-button {
  border: 2px solid $color-orange;
  border-radius: 4px;
  color: $color-orange;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 15px 5px 25px;
  position: relative;
  transition: background-color .3s linear;

  &:hover,
  &:focus {
    background-color: $color-orange;
    color: $color-white;
    outline: 0;

    .new-board__left-arrow {
      background-color: $color-white;

      &:before {
        background-color: $color-white;
      }

      &:after {
        background-color: $color-white;
      }
    }
  }
}

.new-board__left-arrow {
  background-color: $color-orange;
  border-radius: 2px;
  display: inline-block;
  height: 2px;
  position: absolute;
  width: 15px;
  left: 5px;
  top: calc(50% - 1px);

  &:before {
    background-color: $color-orange;
    border-radius: 2px;
    content: '';
    display: inline-block;
    height: 2px;
    width: 8px;
    position: absolute;
    top: calc(50% - 4px);
    left: 0;
    transform: rotate(-45deg);
  }

  &:after {
    background-color: $color-orange;
    border-radius: 2px;
    content: '';
    display: inline-block;
    height: 2px;
    width: 8px;
    position: absolute;
    bottom: calc(50% - 4px);
    left: 0;
    transform: rotate(45deg);
  }
}

.new-board__team-creatiion--error {
  margin-top: 16px;
  color: $color-danger;
}

.new-board {
  background-color: #F3F3F3;
  min-height: 100vh;

  @media all and (min-width: 1200px) {
    .container {
      max-width: 1110px;
    }
  }
}

.new-board-nav {
  background-color: #ffffff;
  padding-bottom: 30px;
  padding-top: 30px;
}

.new-board-nav__title {
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 0;
  margin-top: 0;

  span {
    color: $color-orange;
  }
}

.new-board-mode {
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  cursor: pointer;
  height: 180px;
  margin-bottom: 25px;
  overflow: hidden;
  padding: 25px;
  transition: all .3s ease-in-out;
  position: relative;

  @media all and (max-width: 767px) {
    display: block;

    &:before {
      background-color: $color-orange;
      border-radius: 5px;
      bottom: 25px;
      content: '';
      display: block;
      position: absolute;
      right: 31px;
      width: 2px;
      height: 14px;
    }

    &:after {
      background-color: $color-orange;
      border-radius: 5px;
      bottom: 31px;
      content: '';
      display: block;
      right: 25px;
      position: absolute;
      width: 14px;
      height: 2px;
    }

    .new-board-mode__name {
      padding-left: 0;
      margin-bottom: 16px;
      position: relative;
      width: 100%;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    .new-board-mode__features {
      margin-top: 0;

      li {
        color: rgba(0, 0, 0, .6);;

        &:before {
          background-color: rgba(0, 0, 0, .6);
        }
      }
    }
  }

  &:hover,
  &:focus {
    @media all and (min-width: 768px) {
      background-color: $color-orange;

      .new-board-mode__name {
        color: $color-white;
        font-family: 'Inter', 'sans-serif';
        font-weight: 700;
        transform: translateY(-58px);
        margin-left: 25px;
        text-align: left;

        span {
          padding-left: 0;
          margin: 0;

          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 0;
          }
        }
      }
      .new-board-mode__features {
        margin-top: 0;
      }
    }
  }
}

.new-board-mode__name {
  box-sizing: border-box;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;

  @media all and (min-width: 768px) {
    height: max-content;

    span {
      display: inline-block;
      margin-left: 25px;
      margin-right: 25px;
      padding-left: 19px;
      position: relative;
      transition: margin-right .3s, margin-left .3s, padding-left .2s;

      &:before {
        background-color: $color-orange;
        border-radius: 5px;
        content: '';
        display: block;
        height: 14px;
        left: 6px;
        opacity: 1;
        position: absolute;
        transition: opacity .3s;
        width: 2px;
      }

      &:after {
        background-color: $color-orange;
        border-radius: 5px;
        top: 6px;
        content: '';
        display: block;
        left: 0;
        opacity: 1;
        position: absolute;
        transition: opacity .3s;
        width: 14px;
        height: 2px;
      }
    }
  }

  @media all and (max-width: 767px) {
    text-align: left;
  }

}

.new-board-mode-feature {
  display: inline-block;
  height: 100%;
  margin-top: 32px;
  width: 100%;

  @media all and (max-width: 767px) {
    margin-top: 0;
  }
}

.new-board-mode__features {
  align-items: baseline;
  color: $color-white;
  display: flex;
  left: 0;
  flex-direction: column;
  margin-top: 100%;
  transition: margin-top .3s ease-in-out;
  padding: 0 25px;
  position: absolute;
  width: 100%;

  li {
    color: $color-white;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    list-style: none;
    line-height: 24px;

    &:before {
      background-color: $color-white;
      border-radius: 50%;
      content: '';
      display: inline-block;
      margin-bottom: 3px;
      margin-right: 5px;
      height: 3px;
      width: 3px;
    }
  }
}

.new-board-side {
  background-color: #F3F3F3;
  bottom: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: -380px;
  width: 380px;
  z-index: 9;
  transition: right .3s linear;

  @media all and (max-width: 399px) {
    width: 100%;
    right: -100%;
  }

  &.show {
    right: 0;
  }
}

.new-board-side__container {
  min-height: 100%;
  position: relative;
}

.new-board-side-box-container {
  padding-bottom: 75px;
}

.new-board-side__box {
  background-color: $color-white;
  margin-bottom: 10px;
  padding: 25px;
  position: relative;

  .MuiFormControlLabel-root {
    margin-right: 10px;
  }
}

.new-board-side__close {
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  float: right;
  line-height: 1;
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 26px;
  height: 26px;

  &:before {
    background-color: #000000;
    border-radius: 15px;
    content: '';
    display: inline-block;
    height: 2px;
    position: absolute;
    transform: rotate(-45deg);
    width: 18px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &:after {
    background-color: #000000;
    border-radius: 15px;
    content: '';
    display: inline-block;
    height: 18px;
    width: 2px;
    position: absolute;
    transform: rotate(-45deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.new-board-side__title {
  color: #000000;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  width: calc(100% - 30px);
}

.new-board-side__input {
  border-bottom: 1px solid #E0E0E0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: $color-orange;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-height: 70px;
  padding: 0;
  width: 100%;
  transition: border-bottom-color .3s linear;

  .MuiFormHelperText-root.Mui-error,
  .MuiFormLabel-root.Mui-error {
    color: $color-danger;
  }

  .MuiInput-underline.Mui-error:after {
    border-bottom-color: $color-danger;
  }

  label {
    color: rgba(0, 0, 0, .6);
    font-family: 'Inter', sans-serif;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $color-orange;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: #E0E0E0;
  }

  .MuiInputBase-input {
    color: #000000;
    font-family: 'Inter', sans-serif;
  }

  .MuiInput-underline:before {
    border-color: #E0E0E0;
    border-width: 1px;
  }

  .MuiInput-underline:after {
    border-color: $color-orange;
  }

  &::placeholder {
    color: rgba(0, 0, 0, .6);
  }

  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, .6);
  }

  &::-ms-input-placeholder {
    color: rgba(0, 0, 0, .6);
  }

  &.new-board-side__input--first {
    margin-bottom: 30px;
  }

  &:focus {
    border-bottom-color: $color-orange;
    outline: 0;
  }
}

.new-board-side__structure {
  align-items: center;
  border-bottom: 1px solid #F6F0F0;
  color: #000000;
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 20px;
  justify-content: space-between;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.new-board-side__list {
  padding-left: 0;
  margin: 0;

  li {
    color: rgba(0, 0, 0, .6);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    list-style: none;
    padding-left: 9px;
    position: relative;

    &.new-board-side__list-item {
      margin-bottom: 24px;
    }

    &:before {
      background-color: rgba(0, 0, 0, .0);
      border-radius: 50%;
      bottom: 11px;
      content: '';
      display: inline-block;
      height: 3px;
      line-height: 24px;
      position: absolute;
      width: 3px;
      left: 0;
    }
  }
}

.new-board-radio-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.new-board__radio-label {
  .MuiTypography-body1 {
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }

  .MuiRadio-root {
    color: rgba(0, 0, 0, .3);
  }

}


fieldset.MuiFormControl-root {
  width: 100%;
}

.new-board-side__info-icon {
  border: 2px solid rgba(0, 0, 0, .3);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 20px;
  margin-top: 11px;
  position: relative;
  width: 20px;

  &:before {
    background-color: rgba(0, 0, 0, .3);
    border-radius: 50%;
    content: '';
    height: 2px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 3px;
    position: absolute;
    width: 2px;
  }

  &:after {
    background-color: rgba(0, 0, 0, .3);
    border-radius: 5px;
    content: '';
    height: 6px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 6px;
    width: 2px;
  }

  &:hover,
  &:focus {
    &+.secure-info {
      display: block;
    }
  }
}

#portal {
  &.active {
    background-color: rgba(0, 0, 0, .8);
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.secure-info {
  display: none;
  background-color: #ffffff;
  position: absolute;
  top: -76px;
  right: -13px;
  max-width: 215px;

  &.secure-info__private {
    top: -94px;
  }
}

.secure-info-wrapper {
  border-radius: 7px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 15px;
  width: 185px;

  &:before {
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: #ffffff transparent transparent transparent;
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -10px;
    right: 15px;
    height: 0;
    width: 0;
  }
}

.secure-info__title {
  color: $color-orange;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  line-height: 14px;
  margin-top: 0;
  margin-bottom: 7px;
}

.secure-info__description {
  color: #000000;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.new-board-main__title {
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  margin-bottom: 25px;
  margin-top: 32px;
}

.recent-boards {
  margin-top: 58px;
}

.recent-boards__title {
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  line-height: 29px;
  margin-bottom: 25px;
  margin-top: 0;
}

.recent-boards__link {
  color: $color-orange;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  float: right;
  line-height: 29px;
  text-decoration: none;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $color-orange !important;

  &:hover {
    color: rgba(250, 100, 0, 0.04);
  }
}

.new-board-side__list {
  .new-board-side__custom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 0;

    &.new-board-side__custom--saved {
      margin-bottom: 12px;
    }
  }
}

.custom-retro__input {
  width: 100%;

  .MuiOutlinedInput-root:focus {
    outline: none;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0,0,0,0.06);
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid rgba(0,0,0,0.06);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0,0,0,0.06);
    border-radius: 8px;
  }

  input {
    background: $color-white;
    color: rgba(0, 0, 0, 0.9);
    caret-color: $color-orange;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 24px;
    padding: 10px 24px;
    outline: none;

    &::placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.38);
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.custom-retro__delete {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 16px;
  height: 20px;
  padding: 4px;
  margin-left: 16px;

  svg {
    width: 15px;
    height: 18px;
  }

  &.custom-retro__delete--disabled {
    cursor: auto;

    svg * {
      fill: rgba(0, 0, 0, 0.3) !important;
    }
  }
}

.custom-retro__checkbox {

  & + .MuiFormControlLabel-label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }

  &.MuiIconButton-root {
    &:hover {
      background-color: rgba(250, 100, 0, 0.04);
    }

    &.Mui-checked {
      color: $color-orange;

      &:hover {
        background-color: rgba(250, 100, 0, 0.04);
      }
    }
  }
}

.new-board-side__structure .participant__button.participant__button--add:disabled {
  background-color: rgba(0, 0, 0, 0.3);

  &:before {
    background-color: $color-white;
  }

  &:after {
    background-color: $color-white;
  }
}
