@import '../../constants/style';

.banner {
  background-color: $color-danger;
  box-sizing: border-box;
  color: $color-white;
  margin-top: -50px;
  padding-top: 13px;
  padding-bottom: 13px;
  transition: margin-top .3s ease;

  &.banner-shown {
    margin-top: 0;
  }
}

.banner-container {
  display: flex;
  justify-content: space-between;
}

.banner-left {
  color: $color-white;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.banner-right {
  align-items: center;
  display: flex;
}

.banner__link {
  display: block;
  color: $color-white;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  margin-right: 24px;
}

.banner-close {
  cursor: pointer;
  display: block;
  height: 24px;
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
  width: 24px;

  &:before {
    background-color: $color-white;
    border-radius: 0.75px;
    content: '';
    display: inline-block;
    height: 19px;
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1.5px;
  }

  &:after {
    background-color: $color-white;
    border-radius: 0.75px;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
    height: 19px;
    width: 1.5px;
  }
}
