.BoardActionItems {
  background-color: #f3f3f3;
  min-height: 100vh;

  &__content {
    max-width: 1110px;
    margin: 0 auto;
    margin-top: 65px;
  }
}
