@import '../../../constants/style';

.column-header {
  background-color: #ffffff;
  border-top: 1px solid #EFEFEF;
  border-right: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
  padding: 25px 24px;
}

@media #{$desktop} {
  //.column-header {
  //  margin-left: $large-margin;
  //  margin-right: $large-margin;
  //  margin-top: $large-margin;
  //}

  .column-header__navigation {
    display: none;
  }
}

.column-header__title-wrapper {
  display: flex;
}

.column-header__overview-button {
  background: none;
  border: none;
  cursor: pointer;
  height: 24px;
  margin: 3px 0 0 0;

  outline: none;
  padding: 0;
}

.column-header__overview-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover,
  &:focus {
    * {
      stroke: #000000;
    }
  }
}
