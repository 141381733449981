@import '../../constants/style';

.dashboard__boards {
  padding-bottom: 26px;
}

.dashboard-pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.dashboard-pagination {
  background-color: $color-white;
  border-radius: 8px;
  display: flex;
  padding: 8px 0;
}

.dashboard-pagination__item {
  background-color: $color-white;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
  height: 24px;
  width: 24px;
  margin: 0 2px;
  transition: 0.15s ease-in-out background-color;

  &:hover,
  &:focus {
    background-color: rgba(250, 100, 0, 0.04);
  }

  &.dashboard-pagination__item--active {
    background-color: $color-orange;
    color: $color-white;
  }

  &.dashboard-pagination__item--prev {
    position: relative;
    margin: 0 3px 0 5px;

    &:before,
    &:after {
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 1px;
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: 7px;
      width: 1px;
    }

    &:before {
      bottom: 5px;
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
      top: 7px;
    }

    &:hover,
    &:focus {
      background-color: $color-white;
    }
  }

  &.dashboard-pagination__item--next {
    position: relative;
    margin: 0 5px 0 3px;

    &:before,
    &:after {
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 1px;
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: 7px;
      width: 1px;
    }

    &:before {
      bottom: 5px;
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
      top: 7px;
    }

    &:hover,
    &:focus {
      background-color: $color-white;
    }
  }

  &.dashboard-pagination__item--disabled {
    cursor: auto;

    &:before,
    &:after {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
