@import '../../constants/style';

.votes-left {
  border-top: 1px solid #EFEFEF;
  padding: 9px 0 10px 0;
  text-align: center;
  margin-top: -1px;
  z-index: 2;
}

.votes-left__info {
  background-color: $color-lightest-gray;
  border-radius: 20.5px;
  color: rgba(0, 0, 0, 0.9);
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  padding: 9px 42px 10px 42px;
}

.votes-left__number {
  color: $color-orange;
}
