@import '../../constants/style';

.team-card {
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  box-sizing: border-box;
  cursor: pointer;
  flex: 0 0 calc(50% - 16px);
  margin: 0 8px 16px 8px;
  height: 132px;
  padding: 20px 24px;
  position: relative;
  transition: transform .3s linear;

  &:hover,
  &:focus {
    transform: scale(1.02);
  }
}

.dd-menu.team-card-options {
  position: absolute;
  right: 12px;
  top: 22px;

  div.dd-menu-items {
    margin: 0;
    top: -7px;

    ul.dd-items-right {
      background-color: $color-white;
      border: 1px solid rgba(44,44,49,0.06);
      border-radius: 8px;
      box-shadow: 0 0 14px 2px rgba(0,0,0,0.05);
      box-sizing: border-box;
      padding: 15px 20px 7px 20px;
      width: 140px;
    }

  }
}

.dd-menu .dd-menu-items li.team-card-options__item {
  color: rgba(0,0,0,0.90);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  margin-bottom: 8px;

  a {
    color: rgba(0,0,0,0.90);
    text-decoration: none;
  }
}

.team-card__title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,0.90);
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 7px;
  max-height: 66px;
  overflow: hidden;
  width: 96%;
  word-break: break-all;
}

.team-card__activity {
  font-size: 14px;
  color: rgba(0,0,0,0.50);
  font-weight: 400;
  line-height: 24px;
}
